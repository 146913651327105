import { SidebarMenuItem } from "./sidebar-menu-item";
import { Link } from "react-router-dom";

import styles from "./sidebar.module.css";

export const Sidebar = ({ logo, pathToHome, menuOptions, onCloseSideBar, onSidebarRefSet, }) => {

  const getLogo = _ => {
    return <img src={logo} className={styles.headerLogo} />;
  };

  return <div ref={_ref => { onSidebarRefSet && onSidebarRefSet(_ref) }} className={styles.container}>
    <div className={styles.crossBtnEl}>
      <svg xmlns="http://www.w3.org/2000/svg" onClick={()=>{onCloseSideBar()}} className={styles.crossBtnOnMbl} fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
      </svg>
    </div>
    <div className={styles.navigation}>
      <div className={styles.navigationContent}>
        {pathToHome && <Link to={pathToHome}>{getLogo()}</Link>}
        {!pathToHome && getLogo()}
      </div>
      <div className={styles.navLinks}>
        {menuOptions.map(({ icon, label, path, items, }, index) => {
          return <SidebarMenuItem
            onClickOnMenuTOCloseSideBar={onCloseSideBar}
            key={index}
            icon={icon}
            label={label}
            path={path}
            items={items}
          />;
        })}
      </div>
    </div>
  </div>;
};
