// this class is used as enum...
export class ActionType {
  static AccessToken = "ACCESS_TOKEN";
  static User = "USER";
  static CreateProperty = "CREATE_PROPERTY";
  static BreadcrumbData = "BREADCRUMB_DATA";
  static DataTableOptions = "DATA_TABLE_OPTIONS";
  static NonpersistentDataTableOptions = "NONPERSISTENT_DATA_TABLE_OPTIONS";
  static GlobalFlag = "GLOBAL_FLAG";
  static Addresses = "ADDRESSES";
}
