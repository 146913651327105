import { useState } from "react";
import { Link } from "react-router-dom";
import styles from "./sidebar-menu-item-dropdown.module.css";

/*
 * Example: items: []
 * item = { path: "/", label: "Home" }
 */
export const SidebarMenuItemDropdown = ({ isVisible, items, onClickToDropDownCloseSideBAr}) => {

  

  return <div className={`${styles.dropDownInterUser} ${(isVisible && items?.length > 0) ? styles.dropDownInterUserActive : ""} `}>
    {
      items?.map(({ path, label, }, index) => {
        return <Link key={index} onClick={()=>onClickToDropDownCloseSideBAr()} className={styles.dropDownLinks} to={path}>{label}</Link>;
      })
    }
  </div>;
};

/* DropDown Start Add this class for dropDown ${styles.dropDownInterUserActive} */
