import styles from "./styled-select.module.css";

export const StyledSelect = ({ name, className, options, value, onChange, }) => {
  return <select name={name} className={`${styles.select} ${className ?? ""}`} value={value} onChange={onChange}>
    {!options?.length && <option key={-1}>No options found.</option>}
    {options?.length && options.map(({ value, label, }, index) => {
      return <option key={index} value={value}>{label}</option>
    })}
  </select>;
};
