import styles from "./property-details-building.module.css";
import {useState} from "react";


export const PropertyDetailsBuilding = (props) => {

    const [showBuildingInfoOnMobile, setshowBuildingInfoOnMobile]=useState(false);

    const addressInfo = props.buildingInfo.Address;
    // console.log("got it address", addressInfo);
    const buildingInfo = props.buildingInfo?.BuildingInfo;
    const buildingDetails = {
        totalArea: buildingInfo?.totalArea ?? "",
        totalFloor: buildingInfo?.totalFloor ?? "",
        buildingFacing: buildingInfo?.buildingFacing ?? "",
        builtYear: buildingInfo?.buildYear ?? "",
    };
    const utilities = {
        gas: buildingInfo?.gas ?? "",
        water: buildingInfo?.water ?? "",
        electricity: buildingInfo?.electricity ?? "",
    };
    // console.log("Utilities", utilities);

    const amenities = {
        communityHall: buildingInfo?.Facility?.communityHall ?? "",
        driverLaunge: buildingInfo?.Facility?.driverLounge ?? "",
        emergencyStairs: buildingInfo?.Facility?.emergencyStairs ?? "",
        fireSafty: buildingInfo?.Facility?.fireSafty ?? "",
        greenApartment: buildingInfo?.Facility?.greenApartment ?? "",
        playGround: buildingInfo?.Facility?.playGround ?? "",
        reception: buildingInfo?.Facility?.reception ?? "",
        rofftopGarden: buildingInfo?.Facility?.rofftopGarden ?? "",
        security: buildingInfo?.Facility?.security ?? "",
        sharedGym: buildingInfo?.Facility?.sharedGym ?? "",
        sharedPool: buildingInfo?.Facility?.sharedPool ?? "",
        solarPannel: buildingInfo?.Facility?.solarPannel ?? "",
        survailanceCamera: buildingInfo?.Facility?.survailanceCamera ?? "",
    };
    const houseRules = {
        basicRules: buildingInfo?.basicRules ?? "",
        commercialRules: buildingInfo?.commercialRules ?? "",
    };
    // console.log(Object.entries(houseRules),"/////////////////////////////////////////////////////////////////////")
    console.log("got it building", buildingDetails);

    return (
        <div className={buildingInfo?`${styles.buildingInfo}`:``}>
            <div onClick={_=>setshowBuildingInfoOnMobile(!showBuildingInfoOnMobile)} className={styles.buildingTitle}> 
                Building Information
                <span  className={styles.addBtnOnMobile}>+</span>
            </div>
            <div className={showBuildingInfoOnMobile?`${styles.buildingDetails} ${styles.buildingDetailsActive}`:`${styles.buildingDetails}`}>
                {/* showBuildingInfoOnMobile             styles.buildingDetails*/}
                <div
                    className={
                        buildingInfo?.propertyType[0] ||
                            addressInfo?.area
                            ? ""
                            : `${styles.hide}`
                    }
                >
                    <p className={styles.title}>Area</p>
                    <ul className={styles.listings}>
                        <li className={styles.detailsList}>
                            {`${buildingInfo?.propertyType?? ""} Flat in ${addressInfo?.block?`${addressInfo?.block}`:``} ${addressInfo?.area}`}
                        </li>
                    </ul>
                </div>
                <div
                    className={`${buildingInfo?.propertyType ? "" : `${styles.hide}`}`}
                >
                    <p className={`${styles.title}`}>Property Type</p>
                    <ul className={styles.listings}>
                        <li className={styles.detailsList}>
                            {`${buildingInfo?.propertyType?? ""}`}
                        </li>
                    </ul>
                </div>
                <div
                    className={
                        buildingInfo?.totalArea ||
                            buildingInfo?.totalFloor ||
                            buildingInfo?.buildingFacing ||
                            buildingInfo?.buildYear
                            ? ""
                            : `${styles.hide}`
                    }
                >
                    <p className={styles.title}>Building Details</p>
                    <ul className={styles.listings}>
                        {Object.entries(buildingDetails).map(([key, value]) => {
                            return (
                                <li key={key} className={styles.detailsList}>
                                    {`${key.split(/(?=[A-Z])/).join(" ")}:  ${value?value: "N/A"}`}
                                </li>
                            );
                        })}
                    </ul>
                </div>
                <div
                    className={
                        buildingInfo?.gas ||
                            buildingInfo?.water ||
                            buildingInfo?.electricity
                            ? ""
                            : ` ${styles.hide}`
                    }
                >
                    {/* buildingInfo?.gas */}
                    <p className={styles.title}>Utilities</p>
                    <ul className={styles.listings}>
                        {Object.entries(utilities)?.map(([key, value]) => {
                            // console.log("utilities", key, value)
                            return (
                                <li key={key} className={styles.detailsList}>
                                    {`${key}: ${value === "true" ? "Available" : value?value:"N/A"
                                        }`}
                                </li>
                            );
                        })}
                    </ul>
                </div>
                <div
                    className={
                        buildingInfo?.Facility?.communityHall ||
                            buildingInfo?.Facility?.driverLaunge ||
                            buildingInfo?.Facility?.emergencyStairs ||
                            buildingInfo?.Facility?.fireSafty ||
                            buildingInfo?.Facility?.greenApartment ||
                            buildingInfo?.Facility?.playGround ||
                            buildingInfo?.Facility?.reception ||
                            buildingInfo?.Facility?.rofftopGarden ||
                            buildingInfo?.Facility?.security ||
                            buildingInfo?.Facility?.sharedGym ||
                            buildingInfo?.Facility?.sharedPool ||
                            buildingInfo?.Facility?.solarPannel ||
                            buildingInfo?.Facility?.survailanceCamera
                            ? " "
                            : `${styles.hide}`
                    }
                >
                    <p className={styles.title}>Amenities</p>
                    <ul className={styles.listings}>
                        {Object.entries(amenities).map(([key, value]) => {
                            // console.log(key,value ,typeof value);
                            return value ? (
                                <li key={value} className={styles.detailsList}>
                                    {`${key
                                        .split(/(?=[A-Z])/)
                                        .join(" ")}`}
                                </li>
                            ) : (
                                ""
                            );
                        })}
                    </ul>
                </div>
                <div
                    className={
                        houseRules?.commercialRules?.length > 0 ||
                            houseRules?.basicRules?.length > 0
                            ? ""
                            : `${styles.hide}`
                    }
                >
                    <p className={styles.title}>House rules</p>
                    <ul className={styles.listings}>
                        
                        {Object.entries(houseRules).map(([name, value]) => {
                           
                            // console.log("lets try", value)
                            return (
                                <li key={name} className={styles.detailsList}>
                                    {`${name
                                        .split(/(?=[A-Z])/)
                                        .join(" ")}`}
                                    {Object.entries(value)?.map(([_, value]) => {
                                        return <ul>
                                            <li key={value} className={styles.subDetailsList}>{value}</li>
                                        </ul>;
                                    })}
                                </li>
                            );
                        })}
                    </ul>
                </div>
                <div
                    className={
                        buildingInfo?.areaFacilities.length > 0 ? "" : `${styles.hide}`
                    }
                >
                    <p className={styles.title}>Area Facilities</p>
                    <ul className={styles.listings}>
                        {buildingInfo?.areaFacilities?.map((fac,i) => {
                            return <li  key={i} className={styles.detailsList}>{fac}</li>;
                        })}
                    </ul>
                </div>
            </div>
        </div>
    );
};
