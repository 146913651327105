import { useState } from "react";
import { Link } from "react-router-dom";
import { SidebarMenuItemDropdown } from "../sidebar-menu-item-dropdown";
import styles from "./sidebar-menu-item.module.css";

// example: dropdown = { isVisible, items = [], }
export const SidebarMenuItem = ({ icon, label, items, path, onClickOnMenuTOCloseSideBar}) => {
  const [isDropdownMenuVisible, setDropdownMenuVisible] = useState(true);
 const closeSideBar=()=>{
  onClickOnMenuTOCloseSideBar()
 }
  return <>
    <div className={styles.navLinkContents} onClick={event => {setDropdownMenuVisible(!isDropdownMenuVisible);}}>
      <img src={icon} className={styles.linkIcons} />
      { path && <Link className={styles.linkTxt} to={path}>{label}</Link> }
      { !path && <p className={styles.linkTxt}>{label}</p> }
    </div>
    <SidebarMenuItemDropdown onClickToDropDownCloseSideBAr={closeSideBar} isVisible={isDropdownMenuVisible} items={items} />
  </>;
};
