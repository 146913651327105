import { Link } from "react-router-dom";
import { BodySidebarMenuItemDropdown } from "..";
import { StringUtilities } from "../../../utilities";

import styles from "./body-sidebar-menu-item.module.css";

export const BodySidebarMenuItem = ({ selectedLabel, selectedDropdownLabel, label, path, onClick, renderBeforeLabel, renderAfterLabel, tags, disabled, items, }) => {
  // checks if this menu item is selected...
  const isSelected = StringUtilities.toAlphanumeric(selectedLabel) === StringUtilities.toAlphanumeric(label);
  // prepares class name based on selection status...
  let className = isSelected ? `${styles.links} ${styles.activeLink}` : styles.links;
  /* const [isDropdownMenuVisible, setDropdownMenuVisible] = useState(true); */

  if (disabled) {
    className += ` ${styles.disabled}`;       // <-- there's a space...
  }

  const onClicked = event => {
    onClick && onClick(label, path, event);
  };

  return <>
    <div className={styles.bodySideBar}>
      {renderBeforeLabel && renderBeforeLabel(selectedLabel, label, path, tags)}
      {path && <Link className={label==="Property"|| label==="Contact"|| label==="Address"?`${className} ${styles.required }`:className} onClick={onClicked} to={path}>{label} {renderAfterLabel && renderAfterLabel(selectedLabel, label, path, tags)}</Link>}
      {!path && <p className={className} onClick={onClicked}>{label}</p>}

    </div>
    {/* If a menu item is selected, we want the dropdown menu to be visible. */}
    <BodySidebarMenuItemDropdown isVisible={isSelected}  items={items} selectedLabel={selectedDropdownLabel} />
  </>;
};
