export class ArrayUtilities {

  static push(element, array) {
    if (Array.isArray(array)) {
      array.push(element);

      return [ ...array ];
    }

    return [ element ];
  }

  static findElementByPropertyValue(propertyName, propertyValue, array = []) {
    for (const element of array) {
      if (element?.[propertyName] === propertyValue) { return element; }
    }
  
    return {};
  };
}
