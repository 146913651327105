import { RestApiClient } from "@shahadul-17/rest-api-client";
import { useEffect, useState } from "react";

import styles from "./service-card.module.css";

export const ServiceCard = ({ index, orderId, userId, serviceDetails, minimumUnits, maximumUnits, onBeforeDelete, onDelete, viewOnly, }) => {
  const restApiClient = RestApiClient.getInstance();
  const [_serviceDetails, setServiceDetails] = useState({});

  const mergeServiceDetails = entry => {
    setServiceDetails({
      ..._serviceDetails,
      ...entry,
    });
  };

  const onInputValueChanged = event => {
    mergeServiceDetails({ [event.target.name]: event.target.value, });
  };

  const onUpdateButtonClickedAsync = async event => {
    await restApiClient.sendSmartRequestAsync({
      routeName: "updateOrder",
      data: {
        orderId: orderId,
        serviceId: _serviceDetails.serviceId,
        unit: _serviceDetails.unit,
      },
    });
  };

  const onDeleteButtonClickedAsync = async (index, event) => {
    let shallDelete = true;

    if (onBeforeDelete) {
      shallDelete = onBeforeDelete(index);
    }

    if (!shallDelete) { return; }

    const response = await restApiClient.sendSmartRequestAsync({
      routeName: "deleteServiceFromOrder",
      data: {
        orderId: orderId,
        serviceId: serviceDetails.serviceId,
      },
    });

    if (response.status !== 200) { return; }

    onDelete && onDelete(index, response);
  };

  useEffect(() => {
    setServiceDetails(serviceDetails ?? {});
  }, [serviceDetails]);

  return <div className={styles.serviceCard}>

    <p className={styles.extendTitle}>{serviceDetails.headline}</p>
    <div className={`${styles.basicServiceCardInfos} ${styles.extend}`}>
      <p>Price:<span> {serviceDetails.price} BDT/unit</span></p>

      <p>Sub total:<span> {serviceDetails.subTotal}</span></p>
      <p>Total: <span>{serviceDetails.total}</span></p>
    </div>
    <div className={`${styles.units} ${styles.extend}`}>
      <p>Units: </p>
      <input type="number" name="unit" min={minimumUnits ?? 1}
        max={maximumUnits ?? 50} value={_serviceDetails.unit}
        onChange={onInputValueChanged} readOnly={viewOnly} />
      {!viewOnly &&  <svg xmlns="http://www.w3.org/2000/svg" type="button" className={styles.updateBtn} onClick={onUpdateButtonClickedAsync} fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
</svg>
      }
    </div>
    

    {!viewOnly && <svg xmlns="http://www.w3.org/2000/svg" className={styles.deleteBtn} type="button" onClick={async event => await onDeleteButtonClickedAsync(index, event)} fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
</svg>}

    
    
  </div>;
};
// <button className={styles.updateBtn} type="button" onClick={onUpdateButtonClickedAsync}>Update</button>
// <button className={styles.deleteBtn} type="button" onClick={async event => await onDeleteButtonClickedAsync(index, event)}>Delete</button>}
