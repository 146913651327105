import { useEffect } from "react";
import { useHistory } from "react-router";

export const ScrollToTop = ({ children, }) => {
  const history = useHistory();

  useEffect(() => {
    const unregister = history.listen(() => {
      window.scrollTo(0, 0);
    });

    return () => {
      unregister();
    };
  }, []);

  return children;
};
