export class StringUtilities {

  static truncate(data, prefixLength, suffixLength, joinedBy = "...") {
    if (typeof data !== 'string') { return data; }

    // if 'joinedBy' is undefined/null, we set to empty string...
    if (joinedBy === undefined || joinedBy === null) {
      joinedBy = "";
    }

    // if data is smaller than truncated data length,
    // we return original data...
    if (data.length < prefixLength + suffixLength + joinedBy.length) { return data; }

    const prefix = data.substring(0, prefixLength);
    const suffix = data.substring(data.length - suffixLength);
    const truncatedData = `${prefix}${joinedBy}${suffix}`;

    return truncatedData;
  }

  /**
   * Removes characters other than 'a'-'z', 'A'-'Z' and '0'-'9'.
   * May return empty string.
   */
  static toAlphanumeric(text, lowerCased = true) {
    let alphanumeric = "";
    
    if (typeof text !== "string") { return alphanumeric; }

    for (let i = 0; i < text.length; i++) {
      const characterCode = text.charCodeAt(i);

      if ((characterCode < 48 || characterCode > 57) &&
        (characterCode < 65 || characterCode > 90) &&
        (characterCode < 97 || characterCode > 122)) {
        continue;
      }

      alphanumeric += String.fromCharCode(characterCode);
    }

    if (lowerCased) {
      alphanumeric = alphanumeric.toLowerCase();
    }

    return alphanumeric;
  }

  static isUndefinedOrNullOrEmpty(text) {
    return typeof text !== "string" || text.length === 0;
  }

  /**
   * Returns undefined if text is undefined/null/empty string. Otherwise,
   * returns original text.
   * @param {String} text String to check.
   * @returns Returns undefined if text is undefined/null/empty string.
   */
  static getUndefinedOnEmpty(text) {
    return this.isUndefinedOrNullOrEmpty(text) ? undefined : text;
  }
}
