import { Link } from "react-router-dom";
import avatarLarge from "../../resources/images/avatar256x256.png";

import styles from "./profile-card.module.css";

export const ProfileCard = ({ isVisible, designation = "N/A", profileImageSource, onLogout, userName = "N/A", profilePath, }) => {
  /* Add ${styles.profileCardActive} this class to show Profile Card */
  const className = isVisible ? `${styles.profileCard} ${styles.profileCardActive}`
    : styles.profileCard;

  return <div className={className}>
    <img src={profileImageSource} className={styles.profileCardImg} alt="My Profile" onError={event => event.target.src = avatarLarge} />
    <p className={styles.userName}><Link to={profilePath}>{userName}</Link></p>
    <p className={styles.userDesignation}>{designation}</p>

    <div className={styles.logOutSection}>
      <svg xmlns="http://www.w3.org/2000/svg" className={styles.logOutIcon} fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
      </svg>
      <span className={styles.logOutTxt} onClick={onLogout}>Log out</span>
    </div>
  </div>;
};
