import { useEffect, useState } from "react";
import { StyledSelect } from "..";
import { StyledButtonOne } from "../styled-button-one";

import styles from "./mini-item-editor.module.css";

export const MiniItemEditor = ({
  showSelection, selectOptions, selectedValue, onSelectionChanged,
  inputValue, showInputInitially, onInputValueChange, showCancelButton,
  showButtonText, actionButtonText, onShowButtonClick, onActionButtonClick, onCancelButtonClick,
}) => {
  const [showInput, setShowInput] = useState(!!showInputInitially);
  const [_inputValue, setInputValue] = useState("");
  const [_selectedValue, setSelectedValue] = useState("");

  const onInputValueChanged = event => {
    setInputValue(event.target.value);
    onInputValueChange && onInputValueChange(event);
  };

  const onSelectedValueChanged = event => {
    setSelectedValue(event.target.value);
    onSelectionChanged && onSelectionChanged(event);
  };

  const onShowButtonClicked = event => {
    const _showInput = onShowButtonClick ? onShowButtonClick(event) : true;

    // if callback function returns 'false' or
    // input field is already visible, we do nothing...
    if (_showInput === false || showInput) { return; }

    setShowInput(true);
  };

  const onCancelButtonClicked = event => {
    if (!showInput) { return; }

    setShowInput(false);

    onCancelButtonClick && onCancelButtonClick(event);
  };

  const onFormSubmitted = event => {
    event.preventDefault();

    onActionButtonClick && onActionButtonClick(_inputValue, event);
  };

  useEffect(() => {
    setInputValue(inputValue);
    setSelectedValue(selectedValue);
  }, [inputValue, selectedValue]);

  return <div className={styles.addButtonContainer}>
    {showSelection && <StyledSelect options={selectOptions} value={_selectedValue} onChange={onSelectedValueChanged} />}

    {/* onClick on BUTTON Add this class  ${styles.addButtonTxtActive} for the txt field and  */}
    <form className={styles.addButtonForm} onSubmit={onFormSubmitted}>
      {showInput && <input type="text" value={_inputValue} placeholder="Type here" className={styles.addButtonTxtActive} onChange={onInputValueChanged} />}
      {showInput && <StyledButtonOne type="submit" text={actionButtonText} />}
    </form>
    {showInput && showCancelButton && <StyledButtonOne text="Cancel" onClick={onCancelButtonClicked} />}

    {/* onClick remove this given class  ${styles.addButtonCountry} add  ${styles.addButtonOnly} */}
    {!showInput && <StyledButtonOne text={showButtonText} onClick={onShowButtonClicked} />}
  </div>;
};
