import { useState } from "react";
import { BackButton } from "../back-button";
import { StyledSelect } from "../styled-select";

import styles from "./bodyHeader.module.css";

export const BodyHeader = ({ showBackButton, title, sortBy, orderBy, onSortingOrderChange, sortingOptions, onSortingAttributeChange, backButtonLink, }) => {

  const onSortingOrderChanged = event => {
    onSortingOrderChange && onSortingOrderChange(orderBy, event);
  };

  const orderByButtonClassName = orderBy === "asc" ? `${styles.sortIcon} ${styles.rotate}` : styles.sortIcon;
  



  return <div className={styles.header}>
    <div className={styles.headerContainer}>
      {showBackButton !== false && <BackButton goTo={backButtonLink} />}
      <div className={styles.headerTitle}>
        
        <p>{title}</p>
      </div>

      {/* <button onClick={onSortingOrderChanged}>{orderBy}</button> */}
      {/*  <svg xmlns="http://www.w3.org/2000/svg" onClick={onSortingOrderChanged} className={styles.sortIcon} fill="none" viewBox="0 0 24 24" stroke="currentColor">
          {orderBy}<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 4h13M3 8h9m-9 4h9m5-4v12m0 0l-4-4m4 4l4-4" />
      </svg> */}
      {Array.isArray(sortingOptions) && <StyledSelect
        className={styles.select}
        options={sortingOptions}
        value={sortBy}
        onChange={onSortingAttributeChange}
      />}
      {/* <SortByModal className={styles.sortModal} /> */}
      {/* Add this class to rotate ${styles.rotate} the icon onCLick */}
    </div>

    {onSortingOrderChange && <svg xmlns="http://www.w3.org/2000/svg" onClick={onSortingOrderChanged} className={orderByButtonClassName} fill="none" viewBox="0 0 24 24" stroke="currentColor">
      {orderBy}<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 4h13M3 8h9m-9 4h9m5-4v12m0 0l-4-4m4 4l4-4" />
    </svg>}
  </div>;
};
