import { useEffect, useState } from "react";
import styles from "./multiselect.module.css"

const isSelected = (selectedItem, selections) => {
  return !!getSelectionByValue(selectedItem, selections);
};

const getSelectionByValue = (selectedItem, selections) => {
  if (!selections) { return undefined; }

  for (const selection of selections) {
    if (selectedItem === selection.value) {
      return selection;
    }
  }

  return undefined;
};

const addToSelection = (itemToAdd, selections, options) => {
  const _selections = [...selections];
  const selection = getSelectionByValue(itemToAdd, options);

  if (selection) { _selections.push(selection); }

  return _selections;
};

const removeFromSelection = (itemToRemove, selections) => {
  const _selections = [];

  for (const selection of selections) {
    if (itemToRemove === selection.value) { continue; }

    _selections.push(selection);
  }

  return _selections;
};

const CustomCheckbox = ({ name, value, label, onChange, checked, }) => {
  return <div className={styles.checkbox} key={name}>
    <input type="checkbox" name={name}
      value={value}
      onChange={onChange}
      checked={checked} />
    <label className={styles.multiSelectLabel} htmlFor={name}>{label}</label><br /><br />
  </div>;
};

export const Multiselect = ({ name, selections, options, onSelect, onDeselect, onChange, selectAllType, }) => {
  const [selectedItems, setSelectedItems] = useState([]);

  useEffect(() => {
    setSelectedItems(selections);
  }, [selections]);

  const onSelectionChanged = event => {
    const isChecked = event.target.checked;
    let currentlySelectedItems;

    if (isChecked) {
      currentlySelectedItems = addToSelection(event.target.value, selectedItems, options);

      setSelectedItems(currentlySelectedItems);
      onSelect && onSelect(name, event.target.value, currentlySelectedItems, event);
    } else {
      currentlySelectedItems = removeFromSelection(event.target.value, selectedItems);

      setSelectedItems(currentlySelectedItems);
      onDeselect && onDeselect(name, event.target.value, currentlySelectedItems, event);
    }

    onChange && onChange(name, event.target.value, currentlySelectedItems, event);
  };

  const onSelectAllButtonClicked = event => {
    let currentlySelectedItems;
    const shallUnselectAll = options.length === selectedItems.length;

    if (shallUnselectAll) {
      currentlySelectedItems = [];
    } else {
      currentlySelectedItems = [...options];
    }

    setSelectedItems(currentlySelectedItems);
    onChange && onChange(name, undefined, currentlySelectedItems, event);
  };

  return <div className={styles.multiSelect}>
    {selectAllType === "checkbox" && <CustomCheckbox
      name="checkbox_all"
      label="All"
      checked={options.length === selectedItems.length}
      onChange={onSelectAllButtonClicked}
    />}

    {options.map(({ label, value }, index) => {
      return <CustomCheckbox
        key={index}
        name={`checkbox_${index}`}
        value={value}
        label={label}
        checked={isSelected(value, selectedItems)}
        onChange={onSelectionChanged}
      />;
    })}

    {selectAllType === "button" && <button className={styles.selectBtn} type="button" onClick={onSelectAllButtonClicked}>
      {options.length === selectedItems.length ? "Unselect All" : "Select All"}
    </button>}
  </div>;
};
