import { RestApiClient } from "@shahadul-17/rest-api-client";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ArrayUtilities, NavigatorUtilities, } from "../../utilities";
import { Spinner } from "../spinner";

import styles from "./address.module.css"

export const Address = ({ requestTags, addressType, routeName, data, selectedAddress, onChange, onSave, onUpdate, editModeOnly, saveButtonText, renderComponentBeforeButtons, renderComponentAfterButtons, }) => {
  const restApiClient = RestApiClient.getInstance();

  const addresses = useSelector(state => state.addresses);
  const [isLoading, setLoading] = useState(false);
  // const [isLocateButtonDisabled, setLocateButtonDisabled] = useState(true);
  const [addressMode, setAddressMode] = useState(editModeOnly ? "EDIT" : "VIEW");
  const [address, setAddress] = useState({});
  const [selectedCountry, setSelectedCountry] = useState({});
  const [selectedDivision, setSelectedDivision] = useState({});
  const [selectedDistrict, setSelectedDistrict] = useState({});
  const [selectedThana, setSelectedThana] = useState({});

  const onDivisionChanged = event => {
    const division = ArrayUtilities.findElementByPropertyValue(event.target.name, event.target.value, selectedCountry.Divisions ?? []);

    setSelectedDivision(division);
    setSelectedDistrict({});
    setSelectedThana({});
    updateAddress({
      [event.target.name]: event.target.value,
      district: "",
      thana: "",
      area: "",
    });
  };

  const onDistrictChanged = event => {
    const district = ArrayUtilities.findElementByPropertyValue(event.target.name, event.target.value, selectedDivision.Districts ?? []);

    setSelectedDistrict(district);
    setSelectedThana({});
    updateAddress({
      [event.target.name]: event.target.value,
      thana: "",
      area: "",
    });
  };

  const onThanaChanged = event => {
    const thana = ArrayUtilities.findElementByPropertyValue(event.target.name, event.target.value, selectedDistrict.Thanas ?? []);

    setSelectedThana(thana);
    updateAddress({
      [event.target.name]: event.target.value,
      area: "",
    });
  };

  const updateAddress = entry => {
    const updatedAddress = { ...address, ...entry, };

    setAddress(updatedAddress);
    onChange && onChange(updatedAddress);
  };

  const onSaveButtonClickedAsync = async event => {
    event.preventDefault();

    if (addressMode !== "EDIT") { return; }

    const shallUpdate = onSave ? onSave(address) : true;

    if (shallUpdate === false) { return; }

    setLoading(true);

    const response = await restApiClient.sendSmartRequestAsync({
      routeName: routeName,
      data: {
        ...address,
        addressType: addressType,
        ...data,
      },
      requestTags: [
        "IGNORE_PLEASE_WAIT_TOAST",
        "IGNORE_SUCCESS_TOAST",
        ...(Array.isArray(requestTags) ? requestTags : [])
      ],
    });

    setLoading(false);

    if (response.status === 200) {
      !editModeOnly && setAddressMode("VIEW");
    }

    onUpdate && onUpdate(address, response);
  };

  const onLocateButtonClickedAsync = async _ => {
    const isGeolocationAccessible = await NavigatorUtilities.isGeolocationAccessibleAsync();

    if (!isGeolocationAccessible) { return; }

    const coordinates = await NavigatorUtilities.getCurrentGeolocationCoordinatesAsync(
      address.latitude, address.longitude, false);

    updateAddress(coordinates);
  };

  useEffect(() => {
    const _selectedAddress = {
      country: "Bangladesh",
      division: "",
      district: "",
      thana: "",
      area: "",
      block: "",
      section: "",
      sector: "",
      houseNo: "",
      roadNo: "",
      latitude: "",
      longitude: "",
      // village: [],
      addressType: addressType,
      ...selectedAddress,
    };

    // if village is an array, we will extract block, section and sector...
    /*if (Array.isArray(_selectedAddress.village) && _selectedAddress.village.length > 0) {
      _selectedAddress.block = _selectedAddress.village[0]?.substring(6) ?? "";       // substring("block".length)
      _selectedAddress.section = _selectedAddress.village[1]?.substring(8) ?? "";     // substring("section".length)
      _selectedAddress.sector = _selectedAddress.village[2]?.substring(7) ?? "";      // substring("sector".length)
      _selectedAddress.village = undefined;
    }*/

    const country = ArrayUtilities.findElementByPropertyValue("country", _selectedAddress.country, addresses ?? []);
    const division = ArrayUtilities.findElementByPropertyValue("division", _selectedAddress.division, country.Divisions ?? []);
    const district = ArrayUtilities.findElementByPropertyValue("district", _selectedAddress.district, division.Districts ?? []);
    const thana = ArrayUtilities.findElementByPropertyValue("thana", _selectedAddress.thana, district.Thanas ?? []);

    setSelectedCountry(country);
    setSelectedDivision(division);
    setSelectedDistrict(district);
    setSelectedThana(thana);
    setAddress(_selectedAddress);
  }, [addressType, selectedAddress]);

  return <div >
    {isLoading && <Spinner />}
    {!isLoading && <form onSubmit={onSaveButtonClickedAsync}>
      <p className={styles.title}>Address</p>
      <div style={{ display: "grid", gap: "1rem",gridTemplateColumns:"1fr 1fr" }}>
        <div className={styles.addressField} >

          <label htmlFor="road" className={styles.addressFormRequired}>Road</label>
          <input type="text" autoComplete="off" className={styles.addressInputField} name="roadNo" value={address.roadNo ?? ""}
            onChange={event => updateAddress({ [event.target.name]: event.target.value, })}
            readOnly={addressMode === "VIEW"} required />
        </div>
        <div className={styles.addressField}>

          <label htmlFor="house" className={styles.addressFormRequired}>House</label>
          <input type="text" autoComplete="off" className={styles.addressInputField} name="houseNo" value={address.houseNo ?? ""}
            onChange={event => updateAddress({ [event.target.name]: event.target.value, })}
            readOnly={addressMode === "VIEW"} required />
        </div>
      </div>
      <div className={styles.addressField}>
        <label htmlFor="country" className={styles.addressFormRequired}>Country</label>
        <select name="country" className={styles.addressInputField} value={address.country ?? "Bangladesh"} disabled={true/* addressMode === "VIEW" */} required>
          <option value="">None</option>
          {addresses?.map(country => {
            return <option key={country.country} value={country.country}>{country.country}</option>;
          })}
        </select>
      </div>
      <div style={{ display: "flex", gap: "1rem", position:"relative"  }}>

        <div className={styles.addressField}>

          <label htmlFor="latitude">Latitude</label>
          <input type="text" autoComplete="off" className={styles.addressInputField} name="latitude" value={address.latitude ?? ""}
            onChange={event => updateAddress({ [event.target.name]: event.target.value, })}
            readOnly={addressMode === "VIEW"} />
        </div>


        <div className={styles.addressField}>

          <label htmlFor="longitude">Longitude</label>
          <input type="text" autoComplete="off" className={styles.addressInputField} name="longitude" value={address.longitude ?? ""}
            onChange={event => updateAddress({ [event.target.name]: event.target.value, })}
            readOnly={addressMode === "VIEW"} />
        </div>

        <div className={styles.addressField} style={{ width:"fit-content", height: "100%", alignSelf : "flex-end" ,position:"absolute",right:"0",top:"-35%"}}>
          <button type="button" onClick={onLocateButtonClickedAsync} className={styles.addressLoactionBtn}>
            <svg xmlns="http://www.w3.org/2000/svg" className={styles.locationIcon} fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
            </svg>
          </button>
        </div>
      </div>
      <div style={{ display: "flex", gap: "1rem" }}>

        <div className={styles.addressField} >

          <label htmlFor="division" className={styles.addressFormRequired}>Division</label>
          <select name="division" className={styles.addressInputField} value={address.division ?? ""} onChange={onDivisionChanged} disabled={addressMode === "VIEW"} required>
            <option value="">None</option>
            {selectedCountry?.Divisions?.map(division => {
              return <option key={division.division} value={division.division}>{division.division}</option>
            })}
          </select>
        </div>


        <div className={styles.addressField}>

          <label htmlFor="district" className={styles.addressFormRequired}>District</label>
          <select name="district" className={styles.addressInputField} value={address.district ?? ""} onChange={onDistrictChanged} disabled={addressMode === "VIEW"} required>
            <option value="">None</option>
            {selectedDivision?.Districts?.map(district => {
              return <option key={district.district} value={district.district}>{district.district}</option>
            })}
          </select>
        </div>
      </div>


      <div className={styles.addressField}>

        <label htmlFor="thana" className={styles.addressFormRequired}>Thana</label>
        <select name="thana" className={styles.addressInputField} value={address.thana ?? ""} onChange={onThanaChanged} disabled={addressMode === "VIEW"} required>
          <option value="">None</option>
          {selectedDistrict?.Thanas?.map(thana => {
            return <option key={thana.thana} value={thana.thana}>{thana.thana}</option>
          })}
        </select>
      </div>



      <div className={styles.addressField}>

        <label htmlFor="area" className={styles.addressFormRequired}>Area</label>
        <select name="area" className={styles.addressInputField} value={address.area ?? ""}
            onChange={event => updateAddress({ [event.target.name]: event.target.value, })}
            disabled={addressMode === "VIEW"} required>
          <option value="">None</option>
          {selectedThana?.Areas?.map(area => {
            return <option key={area.area} value={area.area}>{area.area}</option>
          })}
        </select>
      </div>

      <div style={{ display: "flex", gap: "1rem" }}>

        <div className={styles.addressField} >

          <label htmlFor="block">Block</label>
          <input type="text" autoComplete="off" className={styles.addressInputField} name="block" value={address.block ?? ""}
            onChange={event => updateAddress({ [event.target.name]: event.target.value, })}
            readOnly={addressMode === "VIEW"} />
        </div>


        <div className={styles.addressField}>

          <label htmlFor="section">Section</label>
          <input type="text" autoComplete="off" className={styles.addressInputField} name="section" value={address.section ?? ""}
            onChange={event => updateAddress({ [event.target.name]: event.target.value, })}
            readOnly={addressMode === "VIEW"} />
        </div>


        <div className={styles.addressField}>

          <label htmlFor="sector">Sector</label>
          <input type="text" autoComplete="off" className={styles.addressInputField} name="sector" value={address.sector ?? ""}
            onChange={event => updateAddress({ [event.target.name]: event.target.value, })}
            readOnly={addressMode === "VIEW"} />
        </div>
      </div>

      <div className={styles.btnContainer}>

        {renderComponentBeforeButtons && renderComponentBeforeButtons()}
        {!editModeOnly && addressMode === "VIEW" && <button type="button" className={styles.btnAll} onClick={_ => setAddressMode("EDIT")}>Edit</button>}
        {addressMode === "EDIT" && <button type="submit" className={styles.btnAll}>{saveButtonText ?? "Save"}</button>}
        {renderComponentAfterButtons && renderComponentAfterButtons()}
      </div>
    </form>}
  </div>;
};
