import styles from "./header.module.css";
import avatarSmall from "../../resources/images/avatar64x64.png";
import avatarLarge from "../../resources/images/avatar256x256.png";
import { ProfileCard } from "../profile-card";
import { Breadcrumb } from "../breadcrumb";
import facebook from "../../resources/images/faceBookLogin.png"
import { useEffect, useState } from "react";
import { configuration } from "../../configuration";
import { useDispatch, useSelector } from "react-redux";
import { setAccessToken, setUser } from "../../redux";
import { UserService } from "../../services";

export const Header = (props) => {
  const [isProfileCardVisible, setProfileCardVisible] = useState(false);
  const [menu, setMenu] = useState(false);

  const [profileImageSmall, setProfileImageSmall] = useState(avatarSmall);
  const [profileImageLarge, setProfileImageLarge] = useState(avatarLarge);

  const user = useSelector(state => state.user);
  const breadcrumbData = useSelector(state => state.breadcrumbData);
  const dispatch = useDispatch();

  const onLogout = _ => {
    // updates redux store...
    dispatch(setAccessToken(""));
    dispatch(setUser(null));
  };

  

  useEffect(() => {
    if (!user?.profileImage) { return; }

    // if user has profile image then we will set both small and large image to user's profile image...
    const userProfileImageUrl = `${configuration.amazonBucketBaseUrl}/${user.profileImage}`;

    setProfileImageSmall(userProfileImageUrl);
    setProfileImageLarge(userProfileImageUrl);
  }, [user]);

  return (
    <div className={styles.headerContainer}>
      <div className={styles.headerNavTop}>
        <div className={styles.headerNavLeft}>
          <div className={menu ?`${styles.menu} ${styles.cross}` : `${styles.menu}`} onClick={() => {setMenu(!menu);props.onMobileViewBtnClick()}} >
            <div className={styles.line1}></div>
            <div className={styles.line2}></div>
            <div className={styles.line3}></div>
          </div>
          {/* <svg xmlns="http://www.w3.org/2000/svg" className={styles.menu} fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                    </svg> */}
          {/* <a className={styles.headerNavLeftTxt}>Dashboard</a>
          <a className={styles.headerNavLeftTxt}>Users</a>
          <a className={styles.headerNavLeftTxt}>Settings</a> */}
        </div>
        <div className={styles.headerNavRight}>

          {/* <svg xmlns="http://www.w3.org/2000/svg" className={styles.bellIcon} fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
          </svg> */}
          {/* <svg xmlns="http://www.w3.org/2000/svg" className={styles.mailIcon} fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
          </svg> */}
          <img src={profileImageSmall} className={styles.profileImg}
              alt="My Profile"
              onError={event => event.target.src = avatarLarge}
              onClick={event => {
            setProfileCardVisible(!isProfileCardVisible);
          }} />

          {/* PROFILE CARD START */}
          <ProfileCard profileImageSource={profileImageLarge}
            isVisible={isProfileCardVisible}
            designation={UserService.getUserType(user) ?? "N/A"}
            userName={user.userName ?? user.phnNo}
            profilePath="/profile"
            onLogout={onLogout} />
          {/* PROFILE CARD END */}
        </div>
      </div>
      <div className={styles.headerPagination}>
        <div className={styles.headerPaginationLeft}>
          {/* <span>Home</span><span>/</span><span>Address</span> */}
          <Breadcrumb data={breadcrumbData} />
        </div>
        <div className={styles.headerPaginationRight}>
          {/* <img src={facebook} className={styles.facebook} /> */}
        </div>
      </div>
    </div>
  );
};