import { useHistory } from "react-router";
import styles from "./back-button.module.css"

export const BackButton = ({ label, onClick, goTo, }) => {
  const history = useHistory();
  const onBackButtonClicked = event => {
    onClick && onClick(event);
    goTo && history.push(goTo);
    !goTo && history.goBack();
  };

  return <div className={styles.ButtomContainer} onClick={onBackButtonClicked}><svg xmlns="http://www.w3.org/2000/svg" className={styles.buttonarrow} fill="none" viewBox="0 0 24 24" stroke="currentColor">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 19l-7-7m0 0l7-7m-7 7h18" />
  </svg></div>;
};
