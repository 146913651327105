import ReactDom from "react-dom";

import styles from "./modal.module.css";

const modalContainer = document.getElementById("modalContainer");

const Overlay=props=>{
  return <div onClick={props.onClose} className={styles.backdrop}></div>
};
const Modalcontent = props => {
  return <div className={styles.modal}>
    <div className={styles.content}>
      {props.children}
    </div>
  </div>
};

const Modal = props => {
  return <>
    {ReactDom.createPortal(<Overlay onClose={props.onClose}/>, modalContainer)}
    {ReactDom.createPortal(<Modalcontent>{props.children}</Modalcontent>, modalContainer)}
  </>
};
export default Modal;
