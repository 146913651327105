import { combineReducers, } from "redux";
import { ActionType } from "./action-type";
import localStorage from "redux-persist/lib/storage";
// import storageSession from "redux-persist/lib/storage/session";
import { persistReducer } from "redux-persist";

const breadcrumbData = (state = [], action) => {
  switch (action.type) {
    case ActionType.BreadcrumbData:
      return action.payload ?? state;
    default:
      return state;
  }
};

const accessToken = (state = "", action) => {
  switch (action.type) {
    case ActionType.AccessToken:
      return action.payload ?? state;
    default:
      return state;
  }
};

const user = (state = null, action) => {
  switch (action.type) {
    case ActionType.User:
      return action.payload ?? null;
    default:
      return state;
  }
};

const propertyCreationData = (state = null, action) => {
  switch (action.type) {
    case ActionType.CreateProperty:
      if (action.payload === undefined) { return state; }
      else if (action.payload === null) { return {}; }

      return { ...state, ...action.payload, };
    default:
      return state;
  }
};

const dataTableOptions = (state = null, action) => {
  switch (action.type) {
    case ActionType.DataTableOptions:
      if (action.payload === undefined) { return state; }
      else if (action.payload === null) { return {}; }

      return { ...state, ...action.payload, };
    default:
      return state;
  }
};

const nonpersistentDataTableOptions = (state = null, action) => {
  switch (action.type) {
    case ActionType.NonpersistentDataTableOptions:
      if (action.payload === undefined) { return state; }
      else if (action.payload === null) { return {}; }

      return { ...state, ...action.payload, };
    default:
      return state;
  }
};

const globalFlag = (state = false, action) => {
  switch (action.type) {
    case ActionType.GlobalFlag:
      return !!action.payload;
    default:
      return state;
  }
};

const addresses = (state = [], action) => {
  switch (action.type) {
    case ActionType.Addresses:
      return action.payload;
    default:
      return state;
  }
};

export const combinedReducer = combineReducers({
  accessToken, user, propertyCreationData, breadcrumbData,
  dataTableOptions, nonpersistentDataTableOptions,
  globalFlag, addresses,
});

export const persistedReducers = persistReducer({
  key: "root",
  storage: localStorage,
  blacklist: [
    "propertyCreationData", "breadcrumbData", "globalFlag",
    "nonpersistentDataTableOptions",
  ],
}, combinedReducer);
