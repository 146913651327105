import { RestApiClient } from "@shahadul-17/rest-api-client";
import { Link } from "react-router-dom";
import { BodyHeader } from "../bodyHeader";
import { DataTable } from "../data-table";
import { SearchBar, } from "../searchBar";
import { StyledSelect, } from "../styled-select";
import { DateUtilities, } from "../../utilities";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setDataTableOptions } from "../../redux";
import { useState } from "react";

import styles from "./service-list.module.css";

const searchOptions = [
  { value: "headline", label: "Headline" },
];

export const ServiceList = ({ showBackButton, backButtonLink, title, onAddToOrderClick, addNew, review, view, edit, deletion, }) => {
  const restApiClient = RestApiClient.getInstance();
  const history = useHistory();
  const dispatch = useDispatch();
  const [flag, setFlag] = useState(true);
  const [searchBy, setSearchBy] = useState(searchOptions[0].value);
  const [headline, setHeadline] = useState("");
  const [page, setPage] = useState(1);
  const unitInputs = {};
  const dataTableOptions = useSelector(state => state.dataTableOptions);

  const onAddButtonClicked = event => {
    history.push("/dashboard/service/create");
  };

  const onAddressSearched = query => {
    setPage(1);
    setHeadline(query);
  };

  const onDeleteButtonClicked = (rowIndex, rowData, event) => {
    if (!window.confirm("Are you sure you want to delete the service?")) { return false; }

    return true;
  };

  const onDeleted = (rowIndex, rowData, response, event) => {
    console.log("Service deleted successfully...");
  };

  const onSortingOrderChanged = orderBy => {
    dispatch(setDataTableOptions({
      allServicesOrderBy: orderBy === "desc" ? "asc" : "desc",
    }));
  };

  const onSortingAttributeChanged = event => {
    dispatch(setDataTableOptions({ allServicesSortBy: event.target.value, }));
  };

  const onServiceStatusChangedAsync = async (rowIndex, columnIndex, rowData, property, event) => {
    const status = event.target.value === "true";
    const response = await restApiClient.sendSmartRequestAsync({
      routeName: "changeServiceStatus",
      data: {
        serviceId: rowData.id,
        status: status,
      },
    });

    if (response.status === 200) {
      setFlag(!flag);
    }
  };

  const onPageChanged = currentPage => {
    setPage(currentPage);
  };

  const columns = [
    { header: "ID", property: "id", },
    { header: "Headline", property: "headline", },
    {
      header: "Overview",
      property: "overview",
      truncate: { prefixLength: 10, suffixLength: 10 },
      dataFormat: value => value ?? "N/A",
    },
    {
      header: "Category",
      property: "Category",
      dataFormat: value => value?.categoryName ?? "N/A",
    },
    {
      header: "Subcategory",
      property: "SubCategory",
      dataFormat: value => value?.title ?? "N/A",
    },
    {
      header: "Price",
      property: "price",
      dataFormat: value => value ?? "N/A",
    },
    {
      header: "Status",
      property: "status",
      dataFormat: value => value ? "Active" : "Inactive",
      /* renderComponentBefore: (rowIndex, columnIndex, rowData, property) => {
        return <StyledSelect
          className={styles.statusSelect}
          value={rowData[property]}
          options={[
            { label: "Active", value: true, },
            { label: "Inactive", value: false, },
          ]}
          onChange={event => onServiceStatusChangedAsync(rowIndex, columnIndex, rowData, property, event)}
        />
      }, */
    },
    {
      header: "Created at",
      property: "createdAt",
      dataFormat: value => value ? DateUtilities.formatDate(value) : "N/A",
    },
  ];

  onAddToOrderClick && columns.push({
    header: "Units",
    dataFormat: _ => "",
    renderComponentBefore: (rowIndex, columnIndex, rowData, property) => {
      return <input type="number" defaultValue={1} min={1} max={50}
        ref={_ref => {
          unitInputs[`unit_${rowIndex}`] = _ref;
        }} />
    },
  });

  onAddToOrderClick && columns.push({
    header: "Add to Order",
    dataFormat: _ => "",
    renderComponentBefore: (rowIndex, columnIndex, rowData, property) => {
      return <svg xmlns="http://www.w3.org/2000/svg" className={styles.seeIcon} onClick={async event => await onAddToOrderClick(unitInputs[`unit_${rowIndex}`].value, rowIndex, columnIndex, rowData, property, event)} fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
    </svg>
    },
  });

  review && columns.push({
    header: "Reviews & Ratings",
    dataFormat: _ => "",
    renderComponentBefore: (rowIndex, columnIndex, rowData, property) => {
      return <Link to={`/dashboard/service/review/all/${rowData.id}`}><svg xmlns="http://www.w3.org/2000/svg" className={styles.seeIcon} fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
      </svg></Link>
    },
  });

  view && columns.push({
    header: "View",
    dataFormat: _ => "",
    renderComponentBefore: (rowIndex, columnIndex, rowData, property) => {
      return <Link to={`/dashboard/service/details/${rowData.id}`}><svg xmlns="http://www.w3.org/2000/svg" className={styles.seeIcon} fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
      </svg></Link>
    },
  });

  edit && columns.push({
    header: "Edit",
    dataFormat: _ => "",
    renderComponentBefore: (rowIndex, columnIndex, rowData, property) => {
      return <Link to={`/dashboard/service/edit/${rowData.id}`}><svg xmlns="http://www.w3.org/2000/svg" className={styles.editIcon} fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
      </svg></Link>
    },
  });

  return <>
    

    <div className={styles.dashBoardContentsBody}>
    <SearchBar
      placeholder={`Search for services by ${searchBy}`}
      onSearch={onAddressSearched}
      showSearchBy={true}
      searchOptions={searchOptions}
      searchBy={searchBy}
      onSearchByChanged={event => setSearchBy(event.target.value)} />
      <BodyHeader title={title}
        showBackButton={showBackButton}
        backButtonLink={backButtonLink}
        orderBy={dataTableOptions?.allServicesOrderBy ?? "asc"}
        sortBy={dataTableOptions?.allServicesSortBy}
        sortingOptions={[
          { label: "Sort by", value: "" },
          { label: "Approved", value: "isApproval" },
          { label: "Price", value: "price" },
          { label: "Rent/Sale", value: "productType" },
          { label: "Creation date", value: "createdAt" },
        ]}
        onSortingOrderChange={onSortingOrderChanged}
        onSortingAttributeChange={onSortingAttributeChanged} />
      <div className={styles.dashBoardContentsBodyData}>
        <div className={styles.tableBody}>
          <div className={styles.allPropertiesAdd}>
            {/* <svg onClick={onAddButtonClicked} xmlns="http://www.w3.org/2000/svg" className={`${styles.addButton}`} fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M18 9v3m0 0v3m0-3h3m-3 0h-3m-2-5a4 4 0 11-8 0 4 4 0 018 0zM3 20a6 6 0 0112 0v1H3v-1z" />
            </svg> */}
            {addNew && <button onClick={onAddButtonClicked} className={`${styles.addButton}`}>Add Service</button>}
          </div>
          <DataTable columns={columns}
            routeName="getService"
            requestData={{
              [searchBy]: headline,
              orderField: dataTableOptions?.allServicesSortBy,// "createdAt",
              orderBy: dataTableOptions?.allServicesOrderBy,// "desc",
              page: page,
            }}
            responseDataProperty="list"
            deletion={deletion ? {
              by: "id",
              as: "serviceId",
              routeName: "deleteService",
              onClick: onDeleteButtonClicked,
              onDelete: onDeleted,
            } : undefined}
            flag={flag}
            onPageChange={onPageChanged}
          />
        </div>
      </div>
    </div>
  </>;
};
