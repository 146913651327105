import { Link } from "react-router-dom";
import { StringUtilities } from "../../../utilities";

import styles from "./body-sidebar-menu-item-dropdown.module.css";

/*
 * Example: items: []
 * item = { path: "/", label: "Home" }
 */
export const BodySidebarMenuItemDropdown = ({ isVisible, items, selectedLabel, }) => {
  return <div className={`${styles.dropDownMenu} ${(isVisible && items?.length > 0) ? styles.menuActive : ""} `}>
    {
      items?.map(({ path, label, renderBeforeLabel, renderAfterLabel, tags, disabled, onClick, }, index) => {
        // checks if this menu item is selected...
        const isSelected = StringUtilities.toAlphanumeric(selectedLabel) === StringUtilities.toAlphanumeric(label);
        // prepares class name based on selection status...
        let className = isSelected ? `${styles.dropDownLinks} ${styles.activeLink}` : styles.dropDownLinks;

        if (disabled) {
          className += ` ${styles.disabled}`;       // <-- there's a space...
        }

        const onClicked = event => {
          onClick && onClick(label, path, event);
        };

        return <div key={index}>
          { renderBeforeLabel && renderBeforeLabel(selectedLabel, label, path, tags) }
          { path && <Link className={className} onClick={onClicked} to={path}>{label}    {renderAfterLabel && renderAfterLabel(selectedLabel, label, path, tags)}</Link> }
          { !path && <p className={className} onClick={onClicked}>{label}</p> }
          
        </div>;
        /* return <Link key={index} className={styles.dropDownLinks} to={path}>{label}</Link>; */
      })
    }
  </div>;
};
