
import styles from "./property-details-address.module.css";


export const PropertyDetailsAddress = (props) => {
    // console.log("got it", props.property)
    const address = props.property;
    // console.log("Address",address)
    return <div className={styles.addressContainer}>

        <p>Id:  <span>{`${address?.id} (${address?.greehoId ?? ""})`}</span></p>
        {address?.User?.userName?<p>Owner Name:  <span>{`${address?.User?.userName[0].toUpperCase()}${address?.User?.userName?.slice(1).toLowerCase()}`}</span></p>:""}
        {address?.User?.phnNo?<p>Owner Number:  <span>{`${address?.User?.phnNo}`}</span></p>:""}
        {address?.User?.email?<p>Email:  <span>{`${address?.User?.email}`}</span></p>:""}
        {address?.ContactInfos?.[0]?.contactName ?<p>Contact: <span>{`${address?.ContactInfos?.[0]?.contactName} (${address?.ContactInfos?.[0]?.contactDesignation}) `}</span></p>:""}
        {address?.ContactInfos?.[0]?.contactNumber?<p>Contact Number: <span>{`${address?.ContactInfos?.[0]?.contactNumber}`}</span></p>:""}
        {address?.BuildingInfo?.buildingName?<p>Building Name:  <span>{`${address?.BuildingInfo?.buildingName}`}</span></p>:""}
        {/* phnNo */}
        {address?.Address?.area?<p>Area:  <span>{`${address?.Address?.area}`}</span></p>:""}
        {/* <p>Country:  <span>{`${address?.Address?.country}`}</span></p> */}
        {/* <p>District:  <span>{`${address?.Address?.district}`}</span></p> */}
        {address?.Address?.division?<p>Division:  <span>{`${address?.Address?.division}`}</span></p>:""}
        {address?.Address?.thana?<p>Thana:  <span>{`${address?.Address?.thana}`}</span></p>:""}
        {address?.Address?.roadNo?<p>Road Number :  <span>{`${address?.Address?.roadNo}`}</span></p>:""}
        {address?.Address?.houseNo?<p>House Number :  <span>{`${address?.Address?.houseNo}`}</span></p>:""}
        {/* <p>Latitude,Longitude: <span>{`${address?.Address?.latitude},${address?.Address?.longitude}`}</span></p> */}
    </div>
        ;
}