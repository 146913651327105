import React, { Suspense, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route } from "react-router";
import { Redirect } from "react-router-dom";
import { RestApiClient } from "@shahadul-17/rest-api-client";
import { UserService } from "../../services";
import { Spinner } from "../spinner";
import { flattenedRoutes } from "../../routes";
import { setBreadcrumbData } from "../../redux";

const generateBreadcrumbData = props => {
  if (!props) { return []; }

  // if match doesn't exist, use computedMatch...
  const _match = props.match ?? props.computedMatch;

  if (!_match) { return []; }

  const breadcrumbData = flattenedRoutes.filter(({ name, path, }) => {
    // we will filter out matched paths...
    return name && _match.path.includes(path);
  }).map(({ name, path }) => {
    // now we need to place parameters to parameterized paths...
    let _path = path;
    const paramEntries = Object.entries(_match.params);

    for (const [key, value] of paramEntries) {
      _path = _path.replace(`:${key}`, value);
    }

    return { path: _path, name: name, };
  });

  return breadcrumbData;
};

const authorizeUser = (props, user) => {
  // if user is super admin, we won"t perform any validation...
  if (user?.superAdmin) { return ""; }

  const {
    path, location, requiredPermissions,
    isProtected,
  } = props;

  const _path = location?.pathname ?? path;
  const encodedPath = encodeURIComponent(_path);

  // if user is not logged in or,
  // user is not an admin or,
  // user doesn"t have sufficient permissions,
  // we will redirect the user to login...
  if (isProtected && !user?.isAdmin) {
    return `/login?redirectedFrom=${encodedPath}`;
  }

  // user doesn"t have sufficient permissions, we will render error page...
  if (requiredPermissions && !UserService.validateUser(user, requiredPermissions)) {
    return `/extended-error?statusCode=401&message=You are not authorized to access this page.`;
  }

  return "";
}

export const ExtendedRoute = props => {
  const dispatch = useDispatch();
  // we don't really need 'isLoggedIn' from redux store. but, without using it,
  // extended route won't re-render on login state change...
  const user = useSelector(state => state.user) ?? {};
  const navigateTo = authorizeUser(props, user);

  useEffect(() => {
    const breadcrumbData = generateBreadcrumbData(props);

    dispatch(setBreadcrumbData(breadcrumbData));
  }, []);

  if (navigateTo) {
    return <Redirect to={navigateTo} />;
  }

  const {
    path, routes, exact, props: properties,
    component: Component,
  } = props;

  return <Route
    path={path}
    render={_props => {
      return <Suspense fallback={<Spinner />}>
        <Component {..._props} {...properties} routes={routes} />
      </Suspense>;
    }}
    exact={exact}
  />;
}
