import { lazy } from "react";

// lazily importing login page...
const Login = lazy(() => import("./pages").then(module => ({ default: module.Login })));
// lazily importing dashboard page...
const Dashboard = lazy(() => import("./pages").then(module => ({ default: module.Dashboard })));
// lazily importing profile page...
const Profile = lazy(() => import("./pages").then(module => ({ default: module.Profile })));
// lazily importing profile page...
const MyProfile = lazy(() => import("./pages").then(module => ({ default: module.MyProfile })));
// lazily importing change password page...
const ChangePassword = lazy(() => import("./pages").then(module => ({ default: module.ChangePassword })));
// lazily importing analytics page...
const Analytics = lazy(() => import("./pages").then(module => ({ default: module.Analytics })));

// lazily importing contact us page...
const ContactUs = lazy(() => import("./pages").then(module => ({ default: module.ContactUs })));

// lazily importing user page...
const User = lazy(() => import("./pages").then(module => ({ default: module.User })));
// lazily importing all users page...
const AllUsers = lazy(() => import("./pages").then(module => ({ default: module.AllUsers })));
// lazily importing add user page...
const UserDetails = lazy(() => import("./pages").then(module => ({ default: module.UserDetails })));
// lazily importing edit user page...
const EditUser = lazy(() => import("./pages").then(module => ({ default: module.EditUser })));
// lazily importing all user properties page...
const AllUserProperties = lazy(() => import("./pages").then(module => ({ default: module.AllUserProperties })));
// lazily importing user wishlist page...
const UserWishlist = lazy(() => import("./pages").then(module => ({ default: module.UserWishlist })));
// lazily importing user cart page...
const UserCart = lazy(() => import("./pages").then(module => ({ default: module.UserCart })));
// lazily importing user orders page...
const UserOrders = lazy(() => import("./pages").then(module => ({ default: module.UserOrders })));

// lazily importing property page...
const Property = lazy(() => import("./pages").then(module => ({ default: module.Property })));
// lazily importing all properties page...
const AllProperties = lazy(() => import("./pages").then(module => ({ default: module.AllProperties })));
// lazily importing property page...
const PropertyDetails = lazy(() => import("./pages").then(module => ({ default: module.PropertyDetails })));
// lazily importing property requests page...
const PropertyRequest = lazy(() => import("./pages").then(module => ({ default: module.PropertyRequest })));
// lazily importing all property requests page...
const AllPropertyRequests = lazy(() => import("./pages").then(module => ({ default: module.AllPropertyRequests })));
// lazily importing add property request page...
const AddPropertyRequest = lazy(() => import("./pages").then(module => ({ default: module.AddPropertyRequest })));
// lazily importing edit property requests page...
const EditPropertyRequest = lazy(() => import("./pages").then(module => ({ default: module.EditPropertyRequest })));
// lazily importing view property requests page...
const PropertyRequestDetails = lazy(() => import("./pages").then(module => ({ default: module.PropertyRequestDetails })));
// lazily importing add property page...
const AddProperty = lazy(() => import("./pages").then(module => ({ default: module.AddProperty })));
// lazily importing property form page...
const PropertyForm = lazy(() => import("./pages").then(module => ({ default: module.PropertyForm })));
// lazily importing contact form page...
const ContactForm = lazy(() => import("./pages").then(module => ({ default: module.ContactForm })));
// lazily importing address form page...
const AddressForm = lazy(() => import("./pages").then(module => ({ default: module.AddressForm })));

// lazily importing service page...
const Service = lazy(() => import("./pages").then(module => ({ default: module.Service })));
// lazily importing all services page...
const AllServices = lazy(() => import("./pages").then(module => ({ default: module.AllServices })));
// lazily importing all service carts page...
const AllServiceCarts = lazy(() => import("./pages").then(module => ({ default: module.AllServiceCarts })));
// lazily importing service order page...
const ServiceOrder = lazy(() => import("./pages").then(module => ({ default: module.ServiceOrder })));
// lazily importing all service orders page...
const AllServiceOrders = lazy(() => import("./pages").then(module => ({ default: module.AllServiceOrders })));
// lazily importing place service order page...
const PlaceServiceOrder = lazy(() => import("./pages").then(module => ({ default: module.PlaceServiceOrder })));
// lazily importing edit service order page...
const EditServiceOrder = lazy(() => import("./pages").then(module => ({ default: module.EditServiceOrder })));
// lazily importing view service order page...
const ViewServiceOrder = lazy(() => import("./pages").then(module => ({ default: module.ViewServiceOrder })));
// lazily importing service order invoice page...
const ServiceOrderInvoice = lazy(() => import("./pages").then(module => ({ default: module.ServiceOrderInvoice })));
// lazily importing add service page...
const AddService = lazy(() => import("./pages").then(module => ({ default: module.AddService })));
// lazily importing service resource page...
const ServiceResource = lazy(() => import("./pages").then(module => ({ default: module.ServiceResource })));
// lazily importing all service resources page...
const AllResources = lazy(() => import("./pages").then(module => ({ default: module.AllResources })));
// lazily importing service reviews and ratings page...
const ServiceReview = lazy(() => import("./pages").then(module => ({ default: module.ServiceReview })));
// lazily importing add service review page...
const AddServiceReview = lazy(() => import("./pages").then(module => ({ default: module.AddServiceReview })));
// lazily importing edit service review page...
const EditServiceReview = lazy(() => import("./pages").then(module => ({ default: module.EditServiceReview })));
// lazily importing all service reviews and ratings page...
const AllServiceReviews = lazy(() => import("./pages").then(module => ({ default: module.AllServiceReviews })));
// lazily importing edit service page...
const EditService = lazy(() => import("./pages").then(module => ({ default: module.EditService })));
// lazily importing service details page...
const ServiceDetails = lazy(() => import("./pages").then(module => ({ default: module.ServiceDetails })));
// lazily importing service category page...
const ServiceCategory = lazy(() => import("./pages").then(module => ({ default: module.ServiceCategory })));
// lazily importing category page...
const Category = lazy(() => import("./pages").then(module => ({ default: module.Category })));
// lazily importing all categories page...
const AllCategories = lazy(() => import("./pages").then(module => ({ default: module.AllCategories })));
// lazily importing add category page...
const AddCategory = lazy(() => import("./pages").then(module => ({ default: module.AddCategory })));
// lazily importing edit category page...
const EditCategory = lazy(() => import("./pages").then(module => ({ default: module.EditCategory })));
// lazily importing category details page...
const CategoryDetails = lazy(() => import("./pages").then(module => ({ default: module.CategoryDetails })));

// lazily importing subcategory page...
const Subcategory = lazy(() => import("./pages").then(module => ({ default: module.Subcategory })));
// lazily importing all subcategories page...
const AllSubcategories = lazy(() => import("./pages").then(module => ({ default: module.AllSubcategories })));
// lazily importing add subcategory page...
const AddSubcategory = lazy(() => import("./pages").then(module => ({ default: module.AddSubcategory })));
// lazily importing edit subcategory page...
const EditSubcategory = lazy(() => import("./pages").then(module => ({ default: module.EditSubcategory })));
// lazily importing subcategory details page...
const SubcategoryDetails = lazy(() => import("./pages").then(module => ({ default: module.SubcategoryDetails })));

// lazily importing building info page...
const BuildingInfo = lazy(() => import("./pages").then(module => ({ default: module.BuildingInfo })));
// lazily importing building info form page...
const BuildingInfoForm = lazy(() => import("./pages").then(module => ({ default: module.BuildingInfoForm })));
// lazily importing lift form page...
const LiftForm = lazy(() => import("./pages").then(module => ({ default: module.LiftForm })));
// lazily importing parking form (building info) page...
const BuildingInfoParkingForm = lazy(() => import("./pages").then(module => ({ default: module.BuildingInfoParkingForm })));
// lazily importing facilities form page...
const FacilitiesForm = lazy(() => import("./pages").then(module => ({ default: module.FacilitiesForm })));

// lazily importing unit info page...
const UnitInfo = lazy(() => import("./pages").then(module => ({ default: module.UnitInfo })));
// lazily importing unit info form page...
const UnitInfoForm = lazy(() => import("./pages").then(module => ({ default: module.UnitInfoForm })));
// lazily importing ac form page...
const AcForm = lazy(() => import("./pages").then(module => ({ default: module.AcForm })));
// lazily importing parking form (unit info) page...
const UnitInfoParkingForm = lazy(() => import("./pages").then(module => ({ default: module.UnitInfoParkingForm })));
// lazily importing equipment form page...
const EquipmentForm = lazy(() => import("./pages").then(module => ({ default: module.EquipmentForm })));
// lazily importing interior form page...
const InteriorForm = lazy(() => import("./pages").then(module => ({ default: module.InteriorForm })));

// lazily importing photos form page...
const PhotosForm = lazy(() => import("./pages").then(module => ({ default: module.PhotosForm })));

// lazily importing all roles page...
const Role = lazy(() => import("./pages").then(module => ({ default: module.Role })));
// lazily importing all roles page...
const AllRoles = lazy(() => import("./pages").then(module => ({ default: module.AllRoles })));
// lazily importing role details page...
const RoleDetails = lazy(() => import("./pages").then(module => ({ default: module.RoleDetails })));
// lazily importing add role page...
const AddRole = lazy(() => import("./pages").then(module => ({ default: module.AddRole })));
// lazily importing edit role page...
const EditRole = lazy(() => import("./pages").then(module => ({ default: module.EditRole })));

// lazily importing settings page...
const Settings = lazy(() => import("./pages").then(module => ({ default: module.Settings })));
// lazily importing address page...
const Address = lazy(() => import("./pages").then(module => ({ default: module.Address })));
// lazily importing country page...
const Country = lazy(() => import("./pages").then(module => ({ default: module.Country })));
// lazily importing division page...
const Division = lazy(() => import("./pages").then(module => ({ default: module.Division })));
// lazily importing district page...
const District = lazy(() => import("./pages").then(module => ({ default: module.District })));
// lazily importing thana page...
const Thana = lazy(() => import("./pages").then(module => ({ default: module.Thana })));
// lazily importing area page...
const Area = lazy(() => import("./pages").then(module => ({ default: module.Area })));

// lazily importing miscellineous page...
const Miscellineous = lazy(() => import("./pages").then(module => ({ default: module.Miscellaneous })));
// lazily importing pet page...
const Pet = lazy(() => import("./pages").then(module => ({ default: module.Pet })));
// lazily importing basic rule page...
const BasicRule = lazy(() => import("./pages").then(module => ({ default: module.BasicRule })));
// lazily importing commercial rule page...
const CommercialRule = lazy(() => import("./pages").then(module => ({ default: module.CommercialRule })));

// lazily importing feedback page...
const Feedback = lazy(() => import("./pages").then(module => ({ default: module.Feedback })));
// lazily importing all feedbacks page...
const AllFeedbacks = lazy(() => import("./pages").then(module => ({ default: module.AllFeedbacks })));
// lazily importing add feedback page...
const AddFeedback = lazy(() => import("./pages").then(module => ({ default: module.AddFeedback })));
// lazily importing feedback details page...
const FeedbackDetails = lazy(() => import("./pages").then(module => ({ default: module.FeedbackDetails })));

// lazily importing error page...
const Error = lazy(() => import("./pages").then(module => ({ default: module.Error })));
// lazily importing extended error page...
const ExtendedError = lazy(() => import("./pages").then(module => ({ default: module.ExtendedError })));

//#region Utility Functions

/**
 * @param {Array<any>} flattenedRoutes 
 * @returns {Array<any>}
 */
const filterUniqueRoutes = flattenedRoutes => {
  const uniquePaths = new Set();
  const uniqueRoutes = [];

  for (let i = 0; i < flattenedRoutes.length; i++) {
    const route = flattenedRoutes[i];

    if (uniquePaths.has(route.path)) { continue; }

    uniqueRoutes.push(route);
    uniquePaths.add(route.path);
  }

  return uniqueRoutes;
};

/**
 * @param {Array<any>} routes
 * @param {Boolean} uniquePaths
 * @param {Boolean} ignoreNameless
 * @returns {Array<any>}
 */
const flattenNestedRoutes = (routes, uniquePaths = false, ignoreNameless = false) => {
  if (!Array.isArray(routes)) { return []; }

  let flattenedRoutes = [];

  for (let i = 0; i < routes.length; i++) {
    const route = routes[i];

    // checks if the route doesn't contain name...
    if (!ignoreNameless && route.name) {
      flattenedRoutes.push(route);
    }

    const _flattenedRoutes = flattenNestedRoutes(route.routes);

    if (_flattenedRoutes.length) {
      flattenedRoutes.push(..._flattenedRoutes);

      continue;
    }
  }

  return uniquePaths ? filterUniqueRoutes(flattenedRoutes) : flattenedRoutes;
};

//#endregion

export const routes = [
  {
    path: "/",
    component: Login,
    exact: true
  },
  {
    path: "/login",
    component: Login,
    exact: true
  },
  {
    path: "/profile",
    component: Profile,
    isProtected: true,
    routes: [
      {
        path: "/profile",
        component: MyProfile,
        exact: true,
        isProtected: true,
      },
      {
        path: "/profile/password/change",
        component: ChangePassword,
        exact: true,
        isProtected: true,
      },
      {
        path: '/',
        component: Error,
        props: {
          statusCode: 404,
          message: 'The requested URL was not found on this server.',
        },
      },
    ],
  },
  {
    name: "Dashboard",
    path: "/dashboard",
    component: Dashboard,
    isProtected: true,
    routes: [
      {
        name: "Dashboard",
        path: "/dashboard",
        component: Analytics,
        exact: true,
        isProtected: true,
        requiredPermissions: [
          "can_view_analytics",
        ],
      },
      {
        name: "Contact Us",
        path: "/dashboard/contact-us",
        component: ContactUs,
        exact: true,
        isProtected: true,
        requiredPermissions: [
          "can_view_contact",
        ],
      },
      {
        name: "All Users",
        path: "/dashboard/user",
        component: User,
        isProtected: true,
        routes: [
          {
            name: "All Users",
            path: "/dashboard/user",
            component: AllUsers,
            exact: true,
            isProtected: true,
            requiredPermissions: [
              "can_view_user",
            ],
          },
          {
            name: "Wishlist",
            path: "/dashboard/user/wishlist/:userId",
            component: UserWishlist,
            exact: true,
            isProtected: true,
            requiredPermissions: [
              "can_view_user",
              "can_view_wishList",
            ],
          },
          {
            name: "User Properties",
            path: "/dashboard/user/property-details/:userId",
            component: AllUserProperties,
            exact: true,
            isProtected: true,
            requiredPermissions: [
              "can_view_user",
              "can_view_property",
            ],
          },
          {
            name: "User Details",
            path: "/dashboard/user/details/:userId",
            component: UserDetails,
            exact: true,
            isProtected: true,
            requiredPermissions: [
              "can_view_user",
            ],
          },
          {
            name: "Edit User",
            path: "/dashboard/user/edit/:userId",
            component: EditUser,
            exact: true,
            isProtected: true,
            requiredPermissions: [
              "can_view_user",
              "can_edit_user",
            ],
          },
          {
            name: "User Cart",
            path: "/dashboard/user/cart/:userId",
            component: UserCart,
            exact: true,
            isProtected: true,
            requiredPermissions: [
              "can_view_cart"
            ],
          },
          {
            name: "User Orders",
            path: "/dashboard/user/order/:userId",
            component: UserOrders,
            exact: true,
            isProtected: true,
            requiredPermissions: [
              "can_get_order_list"
            ],
          },
          {
            path: '/',
            component: Error,
            props: {
              statusCode: 404,
              message: 'The requested URL was not found on this server.',
            },
          },
        ],
      },
      {
        name: "All Properties",
        path: "/dashboard/property",
        component: Property,
        isProtected: true,
        routes: [
          {
            name: "All Properties",
            path: "/dashboard/property",
            component: AllProperties,
            exact: true,
            isProtected: true,
            requiredPermissions: [
              "can_view_property"
            ],
          },
          {
            name: "Property Details",
            path: "/dashboard/property/details/:propertyId",
            component: PropertyDetails,
            exact: true,
            isProtected: true,
            requiredPermissions: [
              "can_view_property"
            ],
          },
          {
            name: "Property Requests",
            path: "/dashboard/property/request",
            component: PropertyRequest,
            isProtected: true,
            routes: [
              {
                name: "Property Requests",
                path: "/dashboard/property/request",
                component: AllPropertyRequests,
                exact: true,
                isProtected: true,
                requiredPermissions: [
                  "can_view_req_property"
                ],
              },
              {
                name: "Add Property Request",
                path: "/dashboard/property/request/create",
                component: AddPropertyRequest,
                exact: true,
                isProtected: true,
              },
              {
                name: "Edit Property Request",
                path: "/dashboard/property/request/edit/:requestId",
                component: EditPropertyRequest,
                exact: true,
                isProtected: true,
                requiredPermissions: [
                  "can_view_req_property"
                ],
              },
              {
                name: "View Property Request",
                path: "/dashboard/property/request/details/:requestId",
                component: PropertyRequestDetails,
                exact: true,
                isProtected: true,
                requiredPermissions: [
                  "can_view_req_property"
                ],
              }
            ]
          },
          {
            path: "/dashboard/property/:operationType/:propertyId/:formType?/:subFormType?",
            component: AddProperty,
            isProtected: true,
            routes: [
              {
                path: "/dashboard/property/:operationType/:propertyId/property",
                component: PropertyForm,
                exact: true,
                isProtected: true,
              },
              {
                path: "/dashboard/property/:operationType/:propertyId/contact",
                component: ContactForm,
                exact: true,
                isProtected: true,
              },
              {
                path: "/dashboard/property/:operationType/:propertyId/address",
                component: AddressForm,
                exact: true,
                isProtected: true,
              },
              {
                path: "/dashboard/property/:operationType/:propertyId/building/:formType?",
                component: BuildingInfo,
                isProtected: true,
                routes: [
                  {
                    path: "/dashboard/property/:operationType/:propertyId/building",
                    component: BuildingInfoForm,
                    exact: true,
                    isProtected: true,
                  },
                  {
                    path: "/dashboard/property/:operationType/:propertyId/building/lift",
                    component: LiftForm,
                    exact: true,
                    isProtected: true,
                  },
                  {
                    path: "/dashboard/property/:operationType/:propertyId/building/parking",
                    component: BuildingInfoParkingForm,
                    exact: true,
                    isProtected: true,
                  },
                  {
                    path: "/dashboard/property/:operationType/:propertyId/building/facilities",
                    component: FacilitiesForm,
                    exact: true,
                    isProtected: true,
                  },
                ]
              },
              {
                path: "/dashboard/property/:operationType/:propertyId/unit/:formType?",
                component: UnitInfo,
                isProtected: true,
                routes: [
                  {
                    path: "/dashboard/property/:operationType/:propertyId/unit",
                    component: UnitInfoForm,
                    exact: true,
                    isProtected: true,
                  },
                  {
                    path: "/dashboard/property/:operationType/:propertyId/unit/ac",
                    component: AcForm,
                    exact: true,
                    isProtected: true,
                  },
                  {
                    path: "/dashboard/property/:operationType/:propertyId/unit/parking",
                    component: UnitInfoParkingForm,
                    exact: true,
                    isProtected: true,
                  },
                  {
                    path: "/dashboard/property/:operationType/:propertyId/unit/equipment",
                    component: EquipmentForm,
                    exact: true,
                    isProtected: true,
                  },
                  {
                    path: "/dashboard/property/:operationType/:propertyId/unit/interior",
                    component: InteriorForm,
                    exact: true,
                    isProtected: true,
                  }
                ]
              },
              {
                path: "/dashboard/property/:operationType/:propertyId/photos",
                component: PhotosForm,
                exact: true,
                isProtected: true,
              },
            ],
          },
          {
            path: '/',
            component: Error,
            props: {
              statusCode: 404,
              message: 'The requested URL was not found on this server.',
            },
          },
        ],
      },
      {
        name: "All Services",
        path: "/dashboard/service",
        component: Service,
        isProtected: true,
        routes: [
          {
            name: "All Services",
            path: "/dashboard/service",
            component: AllServices,
            exact: true,
            isProtected: true,
          },
          {
            name: "Carts",
            path: "/dashboard/service/cart",
            component: AllServiceCarts,
            exact: true,
            isProtected: true,
            requiredPermissions: [
              "can_view_cart"
            ],
          },
          {
            name: "Orders",
            path: "/dashboard/service/order",
            component: ServiceOrder,
            isProtected: true,
            routes: [
              {
                name: "Orders",
                path: "/dashboard/service/order",
                component: AllServiceOrders,
                exact: true,
                isProtected: true,
                requiredPermissions: [
                  "can_get_order_list"
                ],
              },
              {
                name: "Place Order",
                path: "/dashboard/service/order/place",
                component: PlaceServiceOrder,
                exact: true,
                isProtected: true,
                requiredPermissions: [
                  "can_order_by_admin"
                ],
              },
              {
                name: "Edit Order",
                path: "/dashboard/service/order/edit/:orderId",
                component: EditServiceOrder,
                exact: true,
                isProtected: true,
                requiredPermissions: [
                  "can_change_order_status"
                ],
              },
              {
                name: "View Order",
                path: "/dashboard/service/order/view/:orderId",
                component: ViewServiceOrder,
                exact: true,
                isProtected: true,
                /* requiredPermissions: [
                  "XXXXXXX_CAN_GET_ORDER"
                ], */
              },
              {
                name: "Order Invoice",
                path: "/dashboard/service/order/invoice/:orderId",
                component: ServiceOrderInvoice,
                exact: true,
                isProtected: true,
               /*  requiredPermissions: [
                  "can_get_order_list"
                ], */
              }
            ]
          },
          {
            name: "Service Reviews & Ratings",
            path: "/dashboard/service/review",
            component: ServiceReview,
            isProtected: true,
            routes: [
              {
                name: "Add Service Review",
                path: "/dashboard/service/review/create/:serviceId",
                component: AddServiceReview,
                exact: true,
                isProtected: true,
              },
              {
                name: "Edit Service Review",
                path: "/dashboard/service/review/edit/:serviceId/:reviewId",
                component: EditServiceReview,
                exact: true,
                isProtected: true,
              },
              {
                name: "All Service Reviews & Ratings",
                path: "/dashboard/service/review/all/:serviceId",
                component: AllServiceReviews,
                exact: true,
                isProtected: true,
              },
            ]
          },
          {
            name: "Resources",
            path: "/dashboard/service/resource",
            component: ServiceResource,
            isProtected: true,
            routes: [
              {
                name: "Resources",
                path: "/dashboard/service/resource",
                component: AllResources,
                exact: true,
                isProtected: true,
                requiredPermissions: [
                  "can_view_user"
                ],
              },
            ]
          },
          {
            name: "Add Service",
            path: "/dashboard/service/create",
            component: AddService,
            exact: true,
            isProtected: true,
            requiredPermissions: [
              "can_create_service"
            ],
          },
          {
            name: "Edit Service",
            path: "/dashboard/service/edit/:serviceId",
            component: EditService,
            exact: true,
            isProtected: true,
            requiredPermissions: [
              "can_edit_service"
            ],
          },
          {
            name: "Service Details",
            path: "/dashboard/service/details/:serviceId",
            component: ServiceDetails,
            exact: true,
            isProtected: true,
          },
          {
            name: "Service Category",
            path: "/dashboard/service/service-category/:categoryType?",
            component: ServiceCategory,
            isProtected: true,
            routes: [
              {
                name: "Service Category",
                path: "/dashboard/service/service-category/category",
                component: Category,
                isProtected: true,
                routes: [
                  {
                    name: "Service Category",
                    path: "/dashboard/service/service-category/category",
                    component: AllCategories,
                    exact: true,
                    isProtected: true,
                  },
                  {
                    name: "Add Category",
                    path: "/dashboard/service/service-category/category/create",
                    component: AddCategory,
                    exact: true,
                    isProtected: true,
                    requiredPermissions: [
                      "can_create_category_subCategory"
                    ],
                  },
                  {
                    name: "Edit Category",
                    path: "/dashboard/service/service-category/category/edit/:categoryId",
                    component: EditCategory,
                    exact: true,
                    isProtected: true,
                    requiredPermissions: [
                      "can_edit_category_subCategory"
                    ],
                  },
                  {
                    name: "Category Details",
                    path: "/dashboard/service/service-category/category/details/:categoryId",
                    component: CategoryDetails,
                    exact: true,
                    isProtected: true,
                  },
                ],
              },
              {
                name: "Service Category",
                path: "/dashboard/service/service-category/subcategory",
                component: Subcategory,
                isProtected: true,
                routes: [
                  {
                    name: "Service Category",
                    path: "/dashboard/service/service-category/subcategory",
                    component: AllSubcategories,
                    exact: true,
                    isProtected: true,
                  },
                  {
                    name: "Add Subcategory",
                    path: "/dashboard/service/service-category/subcategory/create",
                    component: AddSubcategory,
                    exact: true,
                    isProtected: true,
                    requiredPermissions: [
                      "can_create_category_subCategory"
                    ],
                  },
                  {
                    name: "Edit Subcategory",
                    path: "/dashboard/service/service-category/subcategory/edit/:subcategoryId",
                    component: EditSubcategory,
                    exact: true,
                    isProtected: true,
                    requiredPermissions: [
                      "can_edit_category_subCategory"
                    ],
                  },
                  {
                    name: "Subcategory Details",
                    path: "/dashboard/service/service-category/subcategory/details/:subcategoryId",
                    component: SubcategoryDetails,
                    exact: true,
                    isProtected: true,
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: "/dashboard/settings",
        component: Settings,
        isProtected: true,
        routes: [
          {
            name: "Address",
            path: "/dashboard/settings/address/:addressType?",
            component: Address,
            isProtected: true,
            routes: [
              {
                name: "Country",
                path: "/dashboard/settings/address/country",
                component: Country,
                exact: true,
                isProtected: true,
              },
              {
                name: "Division",
                path: "/dashboard/settings/address/division",
                component: Division,
                exact: true,
                isProtected: true,
              },
              {
                name: "District",
                path: "/dashboard/settings/address/district",
                component: District,
                exact: true,
                isProtected: true,
              },
              {
                name: "Thana",
                path: "/dashboard/settings/address/thana",
                component: Thana,
                exact: true,
                isProtected: true,
              },
              {
                name: "Area",
                path: "/dashboard/settings/address/area",
                component: Area,
                exact: true,
                isProtected: true,
              },
            ],
          },
          {
            name: "Access Roles",
            path: "/dashboard/settings/role",
            component: Role,
            isProtected: true,
            routes: [
              {
                name: "Access Roles",
                path: "/dashboard/settings/role",
                component: AllRoles,
                exact: true,
                isProtected: true,
                requiredPermissions: [
                  "can_view_role"
                ],
              },
              {
                name: "Role Details",
                path: "/dashboard/settings/role/details/:roleId",
                component: RoleDetails,
                exact: true,
                isProtected: true,
                requiredPermissions: [
                  "can_view_role"
                ],
              },
              {
                name: "Add Role",
                path: "/dashboard/settings/role/create",
                component: AddRole,
                exact: true,
                isProtected: true,
                requiredPermissions: [
                  "can_view_permission_list",
                  "can_create_role"
                ],
              },
              {
                name: "Edit Role",
                path: "/dashboard/settings/role/edit/:roleId",
                component: EditRole,
                exact: true,
                isProtected: true,
                requiredPermissions: [
                  "can_view_permission_list",
                  "can_edit_role"
                ],
              },
              {
                path: '/',
                component: Error,
                props: {
                  statusCode: 404,
                  message: 'The requested URL was not found on this server.',
                },
              },
            ],
          },
          {
            name: "Miscellineous",
            path: "/dashboard/settings/misc/:contentType?",
            component: Miscellineous,
            isProtected: true,
            routes: [
              {
                name: "Pet",
                path: "/dashboard/settings/misc/pet",
                component: Pet,
                exact: true,
                isProtected: true,
              },
              {
                name: "Basic Rules",
                path: "/dashboard/settings/misc/basic-rules",
                component: BasicRule,
                exact: true,
                isProtected: true,
              },
              {
                name: "Commercial Rules",
                path: "/dashboard/settings/misc/commercial-rules",
                component: CommercialRule,
                exact: true,
                isProtected: true,
              },
            ],
          },
        ],
      },
      {
        path: "/dashboard/feedback",
        component: Feedback,
        isProtected: true,
        routes: [
          {
            name: "All Feedbacks",
            path: "/dashboard/feedback",
            component: AllFeedbacks,
            exact: true,
            isProtected: true,
          },
          {
            name: "Post Feedback",
            path: "/dashboard/feedback/create",
            component: AddFeedback,
            exact: true,
            isProtected: true,
          },
          {
            name: "Post Feedback",
            path: "/dashboard/feedback/details/:feedbackId",
            component: FeedbackDetails,
            exact: true,
            isProtected: true,
          },
        ],
      },
      {
        path: '/',
        component: Error,
        props: {
          statusCode: 404,
          message: 'The requested URL was not found on this server.',
        },
      },
    ],
  },
  {
    path: '/extended-error',
    component: ExtendedError,
    exact: true,
    isProtected: true,
  },
  {
    path: '/error',
    component: Error,
    exact: true,
  },
  {
    path: '/',
    component: Error,
    props: {
      statusCode: 404,
      message: 'The requested URL was not found on this server.',
    },
  },
];

export const flattenedRoutes = flattenNestedRoutes(routes, true, true);
