import { RestApiClient } from "@shahadul-17/rest-api-client";
import { useEffect, useState } from "react";
import { StringUtilities } from "../../utilities";

import styles from "./search-box.module.css";

const DEFAULT_DEBOUNCE_TIMEOUT = 400;

export const SearchBox = ({
  requestOptions, responseDataProperty, className, id, name,
  placeholder, debounceTimeout, onType, onSearch, onSuggestionsRetrieve,
  onSelect, value, }) => {
  const restApiClient = RestApiClient.getInstance();

  const [timeoutHandle, setTimeoutHandle] = useState(0);
  const [query, setQuery] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [results, setResults] = useState([]);
  const [isMouseEntered, setMouseEntered] = useState(false);
  const [isFocused, setFocused] = useState(false);
  const [isVisible, setVisible] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [readOnly, setReadOnly] = useState(false);

  const onTimedoutAsync = async query => {
    let queryData = undefined;

    if (onSearch) {
      queryData = onSearch(query);
    }

    if (StringUtilities.isUndefinedOrNullOrEmpty(query)) {
      setLoading(false);

      return;
    }

    const userProvidedRequestTags = Array.isArray(requestOptions?.requestTags)
      ? requestOptions.requestTags : [];
    const response = await restApiClient.sendSmartRequestAsync({
      ...requestOptions,
      data: {               // merges existing request data with query data...
        ...requestOptions?.data,
        ...queryData
      },
      requestTags: [        // merges request tags with user provided tags...
        ...userProvidedRequestTags,
        "IGNORE_PLEASE_WAIT_TOAST",
        "IGNORE_SUCCESS_TOAST",
      ],
    });

    if (response.status !== 200) {
      setLoading(false);

      return;
    }

    let results = responseDataProperty ? response.jsonData?.[responseDataProperty] : response.jsonData;

    if (onSuggestionsRetrieve) {
      results = onSuggestionsRetrieve(query, results) ?? results;
    }

    results = Array.isArray(results) ? results : [];

    setResults(results);
    setLoading(false);
  };

  const onQueryTypedAsync = event => {
    // if timeout handle is present, we'll clear existing timeout...
    timeoutHandle && window.clearTimeout(timeoutHandle);
    // calls ontype callback if present...
    onType && onType(event);

    const query = event.target.value;

    setLoading(true);
    setQuery(query);

    const _timeoutHandle = window.setTimeout(async () => {
      // performs search after a certain time interval...
      await onTimedoutAsync(query);
    }, debounceTimeout ?? DEFAULT_DEBOUNCE_TIMEOUT);

    setTimeoutHandle(_timeoutHandle);
  };

  const onSearchFieldFocused = _ => {
    setFocused(true);
    setVisible(true);
  };

  const onSearchFieldFocusLost = _ => {
    setFocused(false);
    !isMouseEntered && setVisible(false);
  };

  const onClickedAsync = async (index, result, event) => {
    setSelectedIndex(index);
    setQuery(result.label);
    setVisible(false);
    setReadOnly(true);

    onSelect && await onSelect(result);
  };

  useEffect(() => {
    if (!value) { return; }

    setQuery(value.label);
    setReadOnly(true);
  }, [ value, ]);

  return <div style={{position:"relative"}} className={`${className ?? ""}`}>
    <div style={{display:"flex",alignItems:"center",justifyContent:"flex-start",width:"100%"}}>

    <input className={styles.searchBoxInput} type="text" id={id} name={name} placeholder={placeholder}
      value={query}
      onFocus={onSearchFieldFocused}
      onBlur={onSearchFieldFocusLost}
      onMouseEnter={_ => setMouseEntered(true)}
      onMouseLeave={_ => {
        setMouseEntered(false);
        !isFocused && setVisible(false);
      }}
      onChange={onQueryTypedAsync}
      readOnly={readOnly}
      />
    {/* <button className={styles.clearBtn} type="button" onClick={_ => {
      setQuery("");
      setReadOnly(false);
    }}>Clear</button> */}
    <svg xmlns="http://www.w3.org/2000/svg" className={styles.clearBtn} type="button" onClick={_ => {
      setQuery("");
      setReadOnly(false);
    }} fill="none" viewBox="0 0 24 24" stroke="currentColor">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
    </svg>
      </div>

    {!readOnly && isVisible && query.length ?
      <div className={styles.suggestionsContainer}
        onMouseEnter={_ => setMouseEntered(true)}
        onMouseLeave={_ => {
          setMouseEntered(false);
          !isFocused && setVisible(false);
        }}>

        {isLoading && <div className={`${styles.hhoOption} ${styles.notSelectable}`}>
          <span className={styles.autoContainerSpan}>Loading...</span>
          <span className={styles.autoContainerSpan}></span>
        </div>}

        {!isLoading && results.length === 0 && <div className={`${styles.hhoOption} ${styles.notSelectable}`}>
          <span className={styles.autoContainerSpan}>No Options Found</span>
          <span className={styles.autoContainerSpan}></span>
        </div>}

        {!isLoading && results.map((result, index) => {
          return <div key={index} tabIndex={result.id}
            onClick={async event => await onClickedAsync(index, result, event)}
            className={`${styles.hhoOption} ${index === selectedIndex ? styles.selected : ""}`}>
            <span className={styles.autoContainerSpan}>{result.label}</span>
            <span className={styles.autoContainerSubSpan}>{result.subLabel}</span>
          </div>;
        })}
      </div> : ""
    }
  </div>;
};
