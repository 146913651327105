import { DataTableColumn } from "../data-table-column";

import styles from "./data-table-row.module.css";

export const DataTableRow = ({ rowIndex, rowData, columns, onClick, onEdit, onDelete, getRowStyle, }) => {
  const _style = getRowStyle ? getRowStyle(rowData) : undefined;

  return <tr style={_style} onClick={onClick ? async event => await onClick(rowData, event) : undefined}>{
    columns.map((column, columnIndex) => {
      return <DataTableColumn
        key={`column_${rowIndex}_${columnIndex}`}
        rowIndex={rowIndex}
        columnIndex={columnIndex}
        rowData={rowData}
        {...column}
      />;
    })
  }
    {
      onEdit && <td>
        <svg xmlns="http://www.w3.org/2000/svg" onClick={event => onEdit(rowIndex, rowData, event)} className={styles.editBtn} fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
        </svg>
      </td>
    }
    {
      onDelete && <td>
        <svg xmlns="http://www.w3.org/2000/svg" onClick={event => onDelete(rowIndex, rowData, event)} className={`${styles.delBtn}`} fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
        </svg>
      </td>
    }
  </tr>;
};
