import { Link } from "react-router-dom";
import styles from "./breadCrumb.module.css"


export const Breadcrumb = ({ data = [] }) => {
  return <>{data.map(({ name, path }, index) => {
    return <div key={`${name}@${path}`}>
      <span className={styles.crumbTitle} key={index}><Link to={path}>{name}</Link></span>
      /
    </div>;
  })}</>
};
