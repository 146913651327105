export class UserService {

  static getUserType(user) {
    if (!user) { return undefined; }

    if (user.superAdmin) { return "Super Admin"; }
    else if (user.isAdmin) { return "Admin"; }

    return "Regular User";
  }

  /** Merges all permissions from all user roles. */
  static getUniquePermissions(user) {
    if (!user || !user.Roles) { return []; }

    const uniquePermissions = new Set();

    for (const role of user.Roles) {
      // if permission is not an array of strings...
      if (!Array.isArray(role.permission)) { continue; }

      role.permission.map(permission => {
        uniquePermissions.add(permission);
      });
    }

    return Array.from(uniquePermissions);
  }

  static validateUser(user, requiredPermissions = []) {
    const userPermissions = this.getUniquePermissions(user);

    return requiredPermissions.every(
      element => userPermissions.includes(element));
  }

  static isUserAllowed(user, requiredPermissions = []) {
    if (!user) { return false; }
    if (user.superAdmin) { return true; }

    return UserService.validateUser(user, requiredPermissions);
  }

  static extractRoleNames(roles = []) {
    const roleNames = [];

    for (const { roleName } of roles) {
      roleNames.push(roleName);
    }

    return roleNames;
  }

  static getRoleOptionByName(roleName, roleOptions = []) {
    for (const roleOption of roleOptions) {
      if (roleOption.label === roleName) { return roleOption; }
    }

    return undefined;
  }

  /**
   * Makes role options from role list.
   * @param {*} roleList 
   * @param {*} roleOptions 
   * @returns 
   */
  static makeRoleOptions(roleList = [], roleOptions = []) {
    const roles = [];

    for (const role of roleList) {
      const roleOption = this.getRoleOptionByName(role.roleName, roleOptions);

      if (!roleOption) { continue; }

      roles.push(roleOption);
    }

    return roles;
  }

  static sanitizeRoles(roles = []) {
    const sanitizedRoles = [];

    for (const { id, roleName } of roles) {
      sanitizedRoles.push({
        value: id.toString(),
        label: roleName,
      });
    }

    return sanitizedRoles;
  }

  /**
   * Formats permissions array to permissions object and includes
   * corresponding labels. 0
   * @param {*} permissionList 
   * @param {*} allPermissions 
   * @returns 
   */
  static formatPermissions(permissionList = [], allPermissions = {}) {
    const permissions = {};

    for (const permissionValue of permissionList) {
      const permissionName = allPermissions[permissionValue];

      permissions[permissionValue] = permissionName;
    }

    return permissions;
  }

  static sanitizePermissions(permissions = {}) {
    const sanitizedRoles = [];

    for (const key in permissions) {
      const value = permissions[key];

      sanitizedRoles.push({
        value: key,
        label: value,
      });
    }

    return sanitizedRoles;
  }
}
