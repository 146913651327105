import { useEffect, useState } from "react";
import { Spinner } from "../spinner";

import styles from "./photo-card.module.css";

export const PhotoCard = ({ index, source, alternateText, onRemove, isRemovable, className, }) => {
  const [isLoading, setLoading] = useState(true);
  const [_source, setSource] = useState("");

  const onRemoveButtonClickedAsync = async event => {
    onRemove && await onRemove(index, source, alternateText, event);
  };

  useEffect(() => {
    if (typeof source === "string") {
      setSource(source);
      setLoading(false);
    }

    // if source is a file, we need to read that as data URL...
    if (source.name) {
      const fileReader = new FileReader();
      fileReader.addEventListener("load", event => {
        setSource(event.target.result);
        setLoading(false);
      });
      fileReader.readAsDataURL(source);
    }
  }, [source]);

  return <div className={`${styles.container} ${className ?? ""}`}>
    {isLoading && <Spinner />}
    {!isLoading && <>
    <div className={styles.gradiant}>

      <img className={styles.image} src={_source} alt={alternateText} />
    </div>
      {isRemovable &&<svg xmlns="http://www.w3.org/2000/svg"  className={styles.deleteImg} onClick={onRemoveButtonClickedAsync} fill="none" viewBox="0 0 24 24" stroke="currentColor">
       <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
   </svg>}
    </>}
  </div>;
};
