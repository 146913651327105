import React, { lazy, Suspense, } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { ScrollToTop, Spinner } from "./components";
import { Startup } from "./startup";

// application root where contents will be
// dynamically rendered by react dom...
const root = document.getElementById("root");
// lazily importing app page...
const App = lazy(() => import("./pages").then(module => ({ default: module.App })));
// lazily importing error page...
const Error = lazy(() => import("./pages").then(module => ({ default: module.Error })));

const onBeforeRenderAsync = async () => {
  const startup = new Startup();
  const result = await startup.configureAsync();
  // retrieves all addresses...
  const response = await result.restApiClient.sendSmartRequestAsync({
    routeName: "getAllAddresses",
    data: { country: "Bangladesh", },
    requestTags: [
      "IGNORE_PLEASE_WAIT_TOAST",
      "IGNORE_SUCCESS_TOAST",
    ],
  });

  return {
    status: 200,
    message: "Initial configuration completed successfully.",
    addresses: response.status === 200 && Array.isArray(response.jsonData?.details) ? response.jsonData.details : [],
    ...result,
  };
};

onBeforeRenderAsync().then(result => {
  // removes the spinner from root...
  document.getElementById("container-d422aee5").remove();

  const { status, message, reduxStore, persistedStore, user, addresses, } = result;

  ReactDOM.render(
    <React.StrictMode>
      <BrowserRouter>
        <ScrollToTop>
          <Suspense fallback={<Spinner />}>
            {status === 200 && <Provider store={reduxStore}>
              <PersistGate loading={<Spinner />} persistor={persistedStore}>
                <App user={user} addresses={addresses} />
              </PersistGate>
            </Provider>}
            {status !== 200 && <Error statusCode={status} message={message} />}
          </Suspense>
        </ScrollToTop>
      </BrowserRouter>
    </React.StrictMode>, root
  );
});
