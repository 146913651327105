import { useState } from "react";
import { StyledSelect } from "../styled-select";

import styles from "./searchBar.module.css";

export const SearchBar = ({ placeholder, debounceTimeout, onType, onSearch, showExportButton, showSearchBy, searchBy, searchOptions, onSearchByChanged, }) => {
  const [searchBarData, setSearchBarData] = useState({
    timeoutHandle: 0,
    query: "",
  });

  const onQueryTypedAsync = event => {
    // if timeout handle is present, we'll clear existing timeout...
    searchBarData.timeoutHandle &&
      window.clearTimeout(searchBarData.timeoutHandle);
    // calls ontype callback if present...
    onType && onType(event);

    const query = event.target.value;

    setSearchBarData({
      query: query,
      // performs debounce...
      timeoutHandle: window.setTimeout(() => {
        onSearch && onSearch(query);
      }, debounceTimeout ?? 400),
    });
  };

  return <div className={styles.searchBarContainer}>
    <div className={styles.searchBarContent}>
      <svg xmlns="http://www.w3.org/2000/svg" className={styles.searchIcon} fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
      </svg>
      <input type="text" className={styles.searchTxt}
        placeholder={placeholder}
        value={searchBarData.query}
        onChange={onQueryTypedAsync} />
    </div>
    {showSearchBy && <StyledSelect className={styles.searchBarExport} options={searchOptions} value={searchBy} onChange={onSearchByChanged} />}
    {showExportButton && <div className={styles.searchBarExport}>
      <svg xmlns="http://www.w3.org/2000/svg" className={styles.exportIcon} fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
      </svg>
      <span className={styles.exportTxt}>Export</span>
    </div>}
  </div>;
}
