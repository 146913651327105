import styles from './property-details-unit.module.css';
import {useState} from "react";

export const PropertyDetailsUnit = (props) => {
    // console.log("Got it UnitInfo", props.unitInfo)
    const [showUnitInfoOnMobile, setshowUnitInfoOnMobile]=useState(false);

    const bedBath = {
        bedRoom: props.unitInfo?.UnitInfo?.bed ?props.unitInfo?.UnitInfo?.bed:"",
        bathRoom: props.unitInfo?.UnitInfo?.bath ?props.unitInfo?.UnitInfo?.bath:"",
        balcony: props.unitInfo?.UnitInfo?.balcony ?props.unitInfo?.UnitInfo?.balcony:"",
        dainingRoom: props.unitInfo?.UnitInfo?.dainingRoom ?? "",
        dainingRoom: props.unitInfo?.UnitInfo?.dainingRoom ?? "",
        drawingRoom: props.unitInfo?.UnitInfo?.drawingRoom ?? "",
        servantBath: props.unitInfo?.UnitInfo?.servantBath ?? "",
        servantBed: props.unitInfo?.UnitInfo?.servantBed ?? "",
    }

    const faltDetails = {
        carpetArea: props.unitInfo?.UnitInfo?.carpetSpace ? props.unitInfo?.UnitInfo?.carpetSpace : "",
        commonArea: props.unitInfo?.UnitInfo?.commonSpace ?props.unitInfo?.UnitInfo?.commonSpace:"",
        floor: props.unitInfo?.floor>3?`${props.unitInfo?.floor}th`?? "":props.unitInfo?.floor===1?`${props.unitInfo?.floor}st`:props.unitInfo?.floor===2?`${props.unitInfo?.floor}nd`:props.unitInfo?.floor===3?`${props.unitInfo?.floor}rd`:"",
        unitNo: props.unitInfo?.unitNo ?? "",
        facing: props.unitInfo?.unitFacing ?? "",

    }
    const kitchen = {
        kitchen: props.unitInfo?.UnitInfo?.kitchen ?? "",
        kitchenCabinet: props.unitInfo?.UnitInfo?.kitchenCabinet ?? "",
        kitchenHood: props.unitInfo?.UnitInfo?.kitchenHood ?? "",
    }
    const interior = {
        bed: props.unitInfo?.UnitInfo?.Furnised?.bed ?? "",
        chairs: props.unitInfo?.UnitInfo?.Furnised?.chairs ?? "",
        closet: props.unitInfo?.UnitInfo?.Furnised?.closet ?? "",
        coffeTable: props.unitInfo?.UnitInfo?.Furnised?.coffeTable ?? "",
        diningTable: props.unitInfo?.UnitInfo?.Furnised?.diningTable ?? "",
        sofa: props.unitInfo?.UnitInfo?.Furnised?.sofa ?? "",
        vanity: props.unitInfo?.UnitInfo?.Furnised?.vanity ?? "",
        wallCabinet: props.unitInfo?.UnitInfo?.Furnised?.wallCabinet ?? "",
        wood: props.unitInfo?.UnitInfo?.Furnised?.wood ?? "",
    }
    const appliances = {
        ac: props.unitInfo?.UnitInfo?.LiftACs[0]?.total ?? "",
        cctv: props.unitInfo?.UnitInfo?.Equipment?.cctv ?? "",
        fan: props.unitInfo?.UnitInfo?.Equipment?.fan ?? "",
        geyser: props.unitInfo?.UnitInfo?.Equipment?.geyser ?? "",
        light: props.unitInfo?.UnitInfo?.Equipment?.light ?? "",
        stove: props.unitInfo?.UnitInfo?.Equipment?.stove ?? "",

    }
    // console.log(Object.entries(appliances).filter(([el, value]) => +value > 0).map(el => console.log("hi", el)))
    const parking = {
        size: props.unitInfo?.UnitInfo?.Parking?.size ?? "",
        capacity: props.unitInfo?.UnitInfo?.Parking?.capacity ?? "",
        type: props.unitInfo?.UnitInfo?.Parking?.type ?? "",
    }
    return <div className={styles.unitInfo}>
        <div onClick={_=>setshowUnitInfoOnMobile(!showUnitInfoOnMobile)} className={styles.unitTitle}>
             Unit Information
             <span  className={styles.addBtnOnMobile}>+</span>
        </div>
        <div className={showUnitInfoOnMobile?`${styles.unitDetails} ${styles.unitDetailsActive}`:`${styles.unitDetails}`}>
            <div className={bedBath?.bedRoom || bedBath?.bathRoom || bedBath?.balcony || bedBath?.dainingRoom || bedBath?.drawingRoom || bedBath?.servantBath || bedBath?.servantBed ? "" : `${styles.hide}`}>

                <p className={styles.title} >Bed & Bath</p>
                <ul className={styles.listings}>
                    {Object.entries(bedBath).filter(([key,value])=>value?`${key}${value}`:"").map(([key, value]) => {
                        
                        return <li className={styles.detailsList}>
                            {typeof value === "number" ? `${value} ${key
                                .split(/(?=[A-Z])/)
                                .join(" ")} ` : `${key
                                    .split(/(?=[A-Z])/)
                                    .join(" ")}`}
                        </li>
                    })}
                </ul>
            </div>
            <div className={faltDetails?.carpetArea||faltDetails?.commonArea||faltDetails?.facing||faltDetails?.floor||faltDetails?.unitNo?"":`${styles.hide}`}>

                <p className={styles.title} >Flat Details</p>
                <ul className={styles.listings}>
                    {Object.entries(faltDetails).filter(([key,value])=>value?`${key}${value}`:"").map(([key, value]) => {
                        {console.log("flat",value?`${key} ${value}`:"")}
                        return <li className={styles.detailsList}>
                            {/* {value} */}
                            {key === "carpetArea" ? `${value} Sq. Feet` : `${key
                                .split(/(?=[A-Z])/)
                                .join(" ")}: ${value?value:"N/A"}`}
                               
                        </li>
                    })}
                </ul>
            </div>
            <div className={kitchen?.kitchen || kitchen?.kitchenCabinet || kitchen?.kitchenHood ? "" : `${styles.hide}`}>
                <p className={styles.title} >Kitchen</p>
                <ul className={styles.listings}>
                    {Object.entries(kitchen).filter(([_, value]) => value).map(([key, value]) => {
                        return <li className={styles.detailsList}>
                            {`${key
                                .split(/(?=[A-Z])/)
                                .join(" ")}: Available`}
                        </li>
                    })}
                </ul>
            </div>
            <div className={interior?.bed || interior?.chairs || interior?.closet || interior?.coffeTable || interior?.diningTable || interior?.sofa || interior?.vanity || interior?.wallCabinet || interior?.wood ? "" : `${styles.hide}`}>

                <p className={styles.title} >Interior</p>
                <ul className={styles.listings}>
                    {Object.entries(interior).filter(([_, value]) => value).map(([key, value]) => {

                        return <li className={styles.detailsList}>
                            {typeof value === "string" ? `${key}: ${value?value:"N/A"}` : `${key
                                .split(/(?=[A-Z])/)
                                .join(" ")}`}
                        </li>
                    })}
                </ul>
            </div>
            <div className={appliances?.ac || appliances?.cctv || appliances?.geyser || appliances?.light || appliances?.stove ? "" : `${styles.hide}`}>

                <p className={styles.title} >Appliances</p>
                <ul className={styles.listings}>
                    {Object.entries(appliances).filter(([el, value]) => +value > 0).map(([key, value]) => {
                        return <li className={styles.detailsList}>
                            {`${key}: ${value?value:"N/A"}`}
                        </li>

                    })}
                </ul>
            </div>
            <div className={parking?.capacity || parking?.size || parking?.type ? "" : `${styles.hide}`}>

                <p className={styles.title} >Parking</p>
                <ul className={styles.listings}>
                    {Object.entries(parking).map(([key, value]) => {
                        return <li className={styles.detailsList}>
                            {key === "size" ? `${value} ft` : `${key}: ${value}`}
                        </li>
                    })}
                </ul>
            </div>
            <div className={props.unitInfo?.UnitInfo?.pet?.length > 0 ? "" : `${styles.hide}`}>

                <p className={styles.title} >Pet Allowed</p>
                <ul className={styles.listings}>

                    {console.log("Pets", props.unitInfo?.UnitInfo?.pet)}
                    {props.unitInfo?.UnitInfo?.pet?.map(el => <li className={styles.detailsList}>
                        {`${el}`}
                    </li>)}
                </ul>
            </div>
        </div>
    </div>
}
{/* <li className={styles.detailsList}>
                    Jatrabari
                </li> */}