import { DateUtilities } from "../../utilities";
import { configuration } from "../../configuration";

import styles from "./analytics-property-requests.module.css";

import avatarSmall from "../../resources/images/avatar64x64.png";

export const AnalyticsPropertyRequests = ({ propertyRequests, }) => {
  return <div className={styles.propertyRequestContainer}>
    <div className={styles.propertyRequestTitle}>Property Requests</div>
    <table className={styles.tableContainer}>
      <tbody>
        <tr className={styles.tableContent}>
          <th></th>
          <th>Requested by</th>
          <th>Area</th>
          <th>Property type</th>
          <th>Rent/Sale</th>
          <th>Submitted date</th>
        </tr>
        {propertyRequests.map((propertyRequest, index) => {
          return <tr className={styles.tableContentRow} key={index}>
            <td colSpan={2}>
              <div className={styles.ServiceInfo}>

                <div >
                  <img className={styles.profileImage}
                    src={propertyRequest.User?.profileImage ? `${configuration.amazonBucketBaseUrl}/${propertyRequest.User?.profileImage}` : avatarSmall}
                    alt="User Profile"
                    onError={event => event.target.src = avatarSmall} />
                </div>
                <div>
                  {propertyRequest.User?.userName ?? propertyRequest.User?.email ?? propertyRequest.User?.phnNo ?? "N/A"}
                </div>
              </div>
            </td>
            <td>{propertyRequest.area ?? "N/A"}</td>
            <td>{propertyRequest.propertyType ?? "N/A"}</td>
            <td>{propertyRequest.reqFor === "rent" ? "Rent" : "Sale"}</td>
            <td>{new Intl.DateTimeFormat("en-us",{weekday:"short",day:"numeric",year:"numeric"}).format(new Date(propertyRequest.createdAt))?? "N/A" ?? "N/A"}</td>
          </tr>;
        })}
      </tbody>
    </table>
  </div>;
};
