import React,{useState}from "react";
import { configuration } from "../../../configuration";
import styles from "./property-details-image.module.css";
import defaultPropertyImage from "../../../resources/images/defaultPropertyImage.jpg"
export const PropertDetailsImage = (props) => {
    const [activeImage,setActiveImage]=useState(0);
   
    
    const images=props.images;
    console.log("images",images)
    if(!Array.isArray(images) || !images.length){
        return <img className={styles.propDefaultCoverImage} src={defaultPropertyImage}/>
    }

    const _activeImage = images?.[activeImage];
    const _imageSource = _activeImage?.startsWith("http") ? _activeImage : `${configuration.amazonBucketBaseUrl}/${_activeImage}`;

    return <div>
        <div className={styles.propDetailsImageConatiner}>
            <img className={styles.propCoverImage} src={_imageSource}/>
            <div className={styles.otherImages}>
                <div className={styles.otherImagesContent}>
                    {
                        images?.map((el,i)=>{
                          const _imageSource = el?.startsWith("http") ? el : `${configuration.amazonBucketBaseUrl}/${el}`;
                          return <img className={activeImage===i?`${styles.allImages} ${styles.activeImage}`:`${styles.allImages}`} key={i}  onClick={()=>setActiveImage(i)} src={_imageSource} />
                        })
                    }
                </div>
            </div>

        </div>
        {/* ${styles.activeImage} */}
    </div>
};