export class StorageManager {

  /** Stores data as key-value pair for a single session (data gets deleted when browser/tab is closed). */
  static SessionStorage = "SESSION_STORAGE";
  /** Stores data as key-value pair. */
  static LocalStorage = "LOCAL_STORAGE";

  /**
   * 
   * @param {"SESSION_STORAGE" | "LOCAL_STORAGE"} type Type of the storage.
   * @returns {undefined | Storage}
   */
  static _getStorage(type) {
    switch (type) {
      case StorageManager.SessionStorage:
        return window.sessionStorage;
      case StorageManager.LocalStorage:
        return window.localStorage;
      default:
        return undefined;
    }
  }

  /**
   * Sets a value to storage.
   * @param {String} key 
   * @param {String | Object} value 
   * @param {"SESSION_STORAGE" | "LOCAL_STORAGE"} storageType Type of the storage.
   * @returns {Boolean} Returns true if data is stored successfully. Otherwise returns false.
   */
  static set(key, value, storageType = "SESSION_STORAGE") {
    const storage = this._getStorage(storageType);

    if (!storage) { return false; }
    else if (typeof value === "object") {
      value = JSON.stringify(value);
    }

    try {
      storage.setItem(key, value);
    } catch (error) {
      console.error("An error occurred while saving item to storage.", error);

      return false;
    }

    return true;
  }

  /**
   * Retrieves an item from storage.
   * @param {String} key 
   * @param {"SESSION_STORAGE" | "LOCAL_STORAGE"} storageType Type of the storage.
   * @returns {undefined | String} Retruns the value associated with the key. Otherwise
   * returns undefined.
   */
  static get(key, storageType = "SESSION_STORAGE") {
    const storage = this._getStorage(storageType);

    if (!storage) { return undefined; }

    const value = storage.getItem(key);

    if (!value) { return undefined; }

    return value;
  }

  /**
   * Removes an entry from storage.
   * @param {String} key 
   * @param {"SESSION_STORAGE" | "LOCAL_STORAGE"} storageType Type of the storage.
   */
  static remove(key, storageType = "SESSION_STORAGE") {
    const storage = this._getStorage(storageType);

    if (!storage) { return; }

    storage.removeItem(key);
  }

  //#region Access Token

  static setAccessToken(token, storageType) {
    return this.set("ACCESS_TOKEN", token, storageType);
  }

  static getAccessToken(storageType) {
    return this.get("ACCESS_TOKEN", storageType);
  }

  static removeAccessToken(storageType) {
    this.remove("ACCESS_TOKEN", storageType);
  }

  //#endregion

  //#region User

  static setUser(user, storageType) {
    return this.set("USER", user, storageType);
  }

  static getUser(storageType) {
    const userJson = this.get("USER", storageType);

    if (!userJson) { return {}; }

    const user = JSON.parse(userJson);

    return user;
  }

  static removeUser(storageType) {
    this.remove("USER", storageType);
  }

  //#endregion

  //#region Instace Count

  static setInstanceCount(instanceCount, storageType) {
    return this.set("INSTANCE_COUNT", instanceCount, storageType);
  }

  static getInstanceCount(storageType) {
    const instanceCount = this.get("INSTANCE_COUNT", storageType);

    if (!instanceCount) { return 0; }

    return parseInt(instanceCount);
  }

  static removeInstanceCount(storageType) {
    this.remove("INSTANCE_COUNT", storageType);
  }

  //#endregion
}
