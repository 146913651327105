import { StringUtilities } from "../../../utilities";

import styles from "./data-table-column.module.css";

export const DataTableColumn = ({ rowIndex, columnIndex, rowData, property, dataFormat, truncate, onClick, renderComponentBefore, renderComponentAfter, }) => {
  let originalData = rowData[property];

  // formats data...
  dataFormat && (originalData = dataFormat(originalData, rowIndex, columnIndex, rowData, property));

  // truncates data...
  const truncatedData = truncate ? StringUtilities.truncate(originalData,
    truncate.prefixLength, truncate.suffixLength, truncate.joinedBy) : originalData;

  return <td className={onClick ? styles.clickableCell : undefined}
    onClick={onClick ? async event => await onClick(rowData, event, property) : undefined}
    key={`column_${rowIndex}_${columnIndex}`}
    title={originalData ?? ""}>
    <div className={styles.singleCell}>
      {/* Renders a component before rendering data (if any). */}
      {renderComponentBefore ? renderComponentBefore(rowIndex, columnIndex, rowData, property) : ""}
      {truncatedData ?? "N/A"}
      {/* Renders a component after rendering data (if any). */}
      {renderComponentAfter ? renderComponentAfter(rowIndex, columnIndex, rowData, property) : ""}
    </div>
  </td>;
};
