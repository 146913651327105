import { useEffect, useState } from "react";
import { BodySidebarMenuItem } from "./body-sidebar-menu-item";

import styles from "./body-sidebar.module.css";

export const BodySidebar = ({ selectedLabel, selectedDropdownLabel, menuOptions, onChange,onSetBodySideBarRef }) => {
  const [selectedMenuItemLabel, setSelectedMenuItemLabel] = useState();

  useEffect(() => {
    setSelectedMenuItemLabel(selectedLabel ?? menuOptions[0].label);
  }, [selectedMenuItemLabel, selectedLabel, selectedDropdownLabel]);

  const onMenuItemClicked = (label, path, event) => {
    setSelectedMenuItemLabel(label);

    onChange && onChange(label, path, event);
  };

  return <div ref={_ref => { onSetBodySideBarRef && onSetBodySideBarRef(_ref) }} className={`${styles.bodyNavigationContainer}`}>
    {/* ${styles.activeBodyNavigation} */}
    <div className={styles.bodyNavigationLinks}>
      {menuOptions.map(({ label, path, tags, renderBeforeLabel, renderAfterLabel, disabled, items, }, index) => {
        return <BodySidebarMenuItem
          key={index}
          label={label}
          path={path}
          tags={tags}
          items={items}
          disabled={disabled}
          selectedLabel={selectedMenuItemLabel}
          selectedDropdownLabel={selectedDropdownLabel}
          onClick={onMenuItemClicked}
          renderBeforeLabel={renderBeforeLabel}
          renderAfterLabel={renderAfterLabel}
        />;
      })}
    </div>
  </div>;
};
