import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { RestApiClient } from "@shahadul-17/rest-api-client";
import { Spinner } from "../spinner";
import { DataTableRow } from "./data-table-row/data-table-row";

import styles from "./data-table.module.css";

export const DataTable = ({ routeName, requestData, requestTags, responseDataProperty, columns, onClick, getRowStyle, onEdit, deletion, flag, onPageChange, }) => {
  const restApiClient = RestApiClient.getInstance();

  const [isLoading, setLoading] = useState(false);
  const [status, setStatus] = useState(undefined);
  const [currentPage, setCurrentPage] = useState(0);
  const [records, setRecords] = useState([]);
  const [, setTotalItems] = useState(0);
  const [totalPages, setTotalPages] = useState(1);

  const retrieveDataAsync = async (page = undefined) => {
    setLoading(true);

    const response = await restApiClient.sendSmartRequestAsync({
      routeName: routeName,
      data: {
        page: page,
        ...requestData,
      },
      requestTags: [
        "IGNORE_PLEASE_WAIT_TOAST",
        "IGNORE_SUCCESS_TOAST",
        ...(Array.isArray(requestTags) ? requestTags : []),
      ],
    });

    setLoading(false);

    if (response.status !== 200) {
      return {
        status: response.status,
        records: [],
        currentPage: 0,
        totalItems: 0,
        totalPages: 1,
      };
    }

    return responseDataProperty ? response.jsonData[responseDataProperty] : response.jsonData;
  }

  const retrieveTableData = selectedPage => {
    retrieveDataAsync(selectedPage).then(({ status, records, currentPage, totalItems, totalPages, }) => {
      setStatus(status);
      setCurrentPage(currentPage - 1);
      setTotalItems(totalItems);
      setTotalPages(totalPages);
      setRecords(records);
    });
  }

  useEffect(() => { retrieveTableData(); }, [flag, requestData]);

  const renderHeader = () => {
    let _columns = [...columns];

    // if "onEdit" or "deletion" is provided, we add empty table header...
    onEdit && _columns.push({ header: "" });
    deletion && _columns.push({ header: "" });

    return _columns.map((column, index) => {
      return <th key={`header_${index}`}>{column.header}</th>;
    });
  };

  const renderItems = () => {
    if (records.length === 0) {
      if (status) {
        return <tr><td style={{ color: "#E55353" }} colSpan={columns.length}>An error occurred during data retrieval (Error Code {status})</td></tr>;
      }

      return <tr><td style={{ color: "#000000" }} colSpan={columns.length}>No Records Found!</td></tr>;
    }

    return records.map((rowData, rowIndex) => {
      return <DataTableRow key={`row_${rowIndex}`}
        rowIndex={rowIndex}
        rowData={rowData}
        onClick={onClick}
        getRowStyle={getRowStyle}
        onEdit={onEdit}
        onDelete={deletion ? onDeletedAsync : undefined}
        columns={columns}
      />;
    });
  };

  const onDeletedAsync = async (rowIndex, rowData, event) => {
    let shallDelete = true;
    const { by, as, routeName, onClick, onDelete, } = deletion;

    // if onClick is provided...
    if (onClick) { shallDelete = await onClick(rowIndex, rowData, event); }
    // if mandatory data not provided...
    if (!shallDelete || !by || !routeName) { return; }

    const response = await restApiClient.sendSmartRequestAsync({
      routeName: routeName,
      data: { [as ?? by]: rowData[by], },
    });

    // if deletion is successful...
    if (response.status === 200) {
      retrieveTableData();
    }

    onDelete && await onDelete(rowIndex, rowData, response, event);
  };

  // this callback function is called on page change...
  const onPageChanged = event => {
    // if on page change is available, we call that function...
    onPageChange && onPageChange(event.selected + 1);

    // retrieveTableData(event.selected + 1);
  };

  return <div className={styles.main}>
    {isLoading && <Spinner />}
    {/* table */}
    {!isLoading && <><div className={styles.tableContainer}>
      <table className={styles.tableStyle}>
        <tbody>
          <tr>{renderHeader()}</tr>
          {renderItems()}
        </tbody>
      </table>
    </div>
      {totalPages !== -1 && records.length !== 0 && <ReactPaginate
        breakLabel="..."
        nextLabel=">"
        previousLabel="<"
        onPageChange={onPageChanged}
        pageRangeDisplayed={1}
        pageCount={totalPages}
        forcePage={currentPage}
        pageClassName={`${styles.recentPageItem}`}
        pageLinkClassName={`${styles.recentPage}`}
        previousClassName="page-item"
        previousLinkClassName={`${styles.prevPage}`}
        nextClassName="page-item"
        nextLinkClassName={`${styles.nextPage}`}
        breakLabel="..."
        breakClassName="page-item"
        breakLinkClassName="page-link"
        containerClassName={`${styles.pagination}`}
        activeClassName={`${styles.activePage}`}
      />}</>}
  </div>;
};
