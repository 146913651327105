import { useEffect, useState } from "react";

import styles from "./multi-input.module.css";

export const MultiInput = ({ type = "text", name = "", removable = true, values = [], onChange, }) => {
  // this is going to be an array of strings...
  const [inputValues, setInputValues] = useState([""]);

  const getInputFieldIndex = inputFieldName => {
    if (!inputFieldName) { return -1; }

    let inputFieldIndex = inputFieldName;
    const lastIndexOfOpeningSquareBracket = inputFieldIndex.lastIndexOf("[");

    if (lastIndexOfOpeningSquareBracket !== -1) {
      inputFieldIndex = inputFieldIndex.substring(lastIndexOfOpeningSquareBracket + 1);
      inputFieldIndex = inputFieldIndex.substring(0, inputFieldIndex.length - 1);
      inputFieldIndex = parseInt(inputFieldIndex);
    }

    if (isNaN(inputFieldIndex)) { return -1; }

    return inputFieldIndex;
  };

  const onInputValueChanged = event => {
    const inputFieldName = event.target.name;
    const inputFieldIndex = getInputFieldIndex(inputFieldName);
    let _inputValues = inputValues;

    if (inputFieldIndex !== -1) {
      _inputValues = [...inputValues];
      _inputValues[inputFieldIndex] = event.target.value;
    }
    
    onChange && onChange({
      target: {
        name: name,
        value: _inputValues,
        currentInputFieldName: inputFieldName,
        currentInputFieldIndex: inputFieldIndex,
        currentInputFieldValue: event.target.value,
      },
    });

    setInputValues(_inputValues);
  };

  const onAddButtonClicked = () => {
    const _inputValues = [ ...inputValues, "" ];

    onChange && onChange({
      target: {
        name: name,
        value: _inputValues,
      },
    });

    setInputValues(_inputValues);
  };

  const onRemoveButtonClicked = (index, event) => {
    const _inputValues = [...inputValues];
    _inputValues.splice(index, 1);

    onChange && onChange({
      target: {
        name: name,
        value: _inputValues,
      },
    });

    setInputValues(_inputValues);
  };

  useEffect(() => {
    if (!Array.isArray(values) || values.length === 0) { return; }

    const inputValues = [ ...values ];

    setInputValues(inputValues);
  }, [ values, ]);

  return <div >
    <div >{
      inputValues.map((value, index) => {
        return <div key={index} className={styles.input}>
          <input type={type}
            name={`${name}[${index}]`}
            value={value ?? ""}
           className={styles.inputField}
            onChange={onInputValueChanged} />
          {removable && <button type="button" className={styles.remove} onClick={event => onRemoveButtonClicked(index, event)}>x</button>}
        </div>;
      })
    }</div>

    <button type="button" className={styles.add} onClick={onAddButtonClicked}>+</button>
    {/* <svg  type="button" xmlns="http://www.w3.org/2000/svg" className={styles.add} onClick={onAddButtonClicked} fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
</svg> */}
    
  </div>;
};
