export class NavigatorUtilities {

  static async isGeolocationAccessibleAsync() {
    const { latitude, longitude, } = await this.getCurrentGeolocationCoordinatesAsync();

    return latitude.length && longitude.length;
  }

  static getCurrentGeolocationCoordinatesAsync(defaultLatitude = "", defaultLongitude = "", logError = false) {
    return new Promise(resolve => {
      let getCurrentPosition = window?.navigator?.geolocation?.getCurrentPosition;

      if (typeof getCurrentPosition !== "function") {
        return resolve({ latitude: defaultLatitude, longitude: defaultLongitude, });
      }

      // binding getCurrentPosition to window.navigator.geolocation...
      getCurrentPosition = getCurrentPosition.bind(window.navigator.geolocation);

      getCurrentPosition(position => {
        const { latitude, longitude, } = position?.coords;

        return resolve({ latitude: latitude.toString(), longitude: longitude.toString(), });
      }, error => {
        logError && console.error("An error occurred while retrieving geolocation data.", error);

        return resolve({ latitude: defaultLatitude, longitude: defaultLongitude, });
      });
    });
  }
}
