import { ActionType } from "./action-type";

export const setAccessToken = accessToken => {
  return {
    type: ActionType.AccessToken,
    payload: accessToken,
  };
};

export const setUser = user => {
  return {
    type: ActionType.User,
    payload: user,
  };
};

export const setPropertyCreationData = data => {
  return {
    type: ActionType.CreateProperty,
    payload: data,
  };
};

export const setBreadcrumbData = data => {
  return {
    type: ActionType.BreadcrumbData,
    payload: data,
  };
};

export const setDataTableOptions = options => {
  return {
    type: ActionType.DataTableOptions,
    payload: options,
  };
};

export const setNonpersistentDataTableOptions = options => {
  return {
    type: ActionType.NonpersistentDataTableOptions,
    payload: options,
  };
};

export const setAddresses = options => {
  return {
    type: ActionType.Addresses,
    payload: options,
  };
};
