import { DateUtilities } from "../../utilities";
import { configuration } from "../../configuration";
import serviceDefaultIcon from "../../resources/images/serviceDefaultIcon.png"

import styles from "./analytics-orders.module.css";

export const AnalyticsOrders = ({ orders, }) => {
  return <div className={styles.orderSummaryContainer}>
    <div className={styles.orderSummaryTitle}>Orders Summary</div>
    <table className={styles.tableContainer}>
      <tbody>
        <tr className={styles.tableContent}>
          <th ></th>
          <th>Ordered by</th>
          <th>Status</th>
          <th>Price (VAT exc.)</th>
          <th>VAT</th>
          <th>Price (VAT inc.)</th>
          <th>Order date</th>
        </tr>
        {orders.map((order, index) => {
          return <tr className={styles.tableContentRow} key={index}>
            <td colSpan={2}>
              <div className={styles.ServiceInfo}>
                <div>
                  <img className={styles.profileImage} src={order.User?.profileImage?`${configuration.amazonBucketBaseUrl}/${order.User?.profileImage}`:`${serviceDefaultIcon}`} alt="User Profile" />
                </div>
                <div>
                  {order.User?.userName ?? order.User?.email ?? order.User?.phnNo ?? "N/A"}
                </div>
              </div>
            </td>
            <td>{order.orderStatus ?? "N/A"}</td>
            <td>{order.actualTotal ? `${order.actualTotal} BDT` : "N/A"}</td>
            <td>{order.vat ? `${order.vat} BDT` : "N/A"}</td>
            <td>{order.total ? `${order.total} BDT` : "N/A"}</td>
            <td>{new Intl.DateTimeFormat("en-us",{weekday:"short",day:"numeric",year:"numeric"}).format(new Date(order.createdAt))?? "N/A"}</td>
          </tr>;
        })}
      </tbody>
    </table>
  </div>;
};
